import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-config";

export enum Dominancy {
  Destro = "destro",
  Canhoto = "canhoto",
}

export interface Costumer {
  name: string;
  birthDate: string;
  occupation: string;
  maritalStatus: string;
  dominancy: Dominancy;
  CPF: string;
  address: string;
  cellPhone: string;
  emergencyPhone?: string;
  email?: string;
}

const costumersCollectionRef = collection(db, "costumers");

export const createCostumer = async (costumer: Costumer) => {
  const createdCostumer = await addDoc(costumersCollectionRef, costumer);
  if (createdCostumer) {
    alert("Cliente cadastrado com sucesso!");
  }
};

export const updateCostumer = async (id: string, costumer: Costumer) => {
  const costumerRef = doc(db, "costumers", id);
  try {
    await setDoc(costumerRef, costumer);
  } catch (e) {
    console.error(e);
  }
  alert("Cliente atualizado com sucesso!");
};
