import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Divider from "../components/Divider";
import Loading from "../components/Loading";
import Spacer from "../components/Spacer";
import TabBar from "../components/TabBar";
import { db } from "../firebase/firebase-config";
import {
  buttonStyle,
  flexStyle,
  headerStyle,
  inputDivStyle,
  inputLabelDivStyle,
  inputStyle,
  selectStyle,
} from "../styles/components";
import { TesteDireitoEEsquerdo, TestesFuncionaisPilates } from "./ReportsCRUD";
import { getAverage, getLevel, getTotalValue } from "./TestesFuncionaisScore";

export const AGACHAMENTO_BIPODAL: { [key: number]: string } = {
  0: "0. Faltam três critérios para score 3.",
  1: "1. Faltam dois critérios para score 3.",
  2: "2. Falta um critério para score 3.",
  3: '3. Flexiona joelho > 45°, MMII alinhados e coluna neutra por 30".',
};

export const FLEXAO_QUADRIL_EM_PE: { [key: number]: string } = {
  0: "0. Desequilibra E não é capaz de flexionar o quadril à 90°.",
  1: "1. Desequilíbrio mas Flexiona quadris 90° OU Mantém equilíbrio, e NÃO flexiona o quadril à 90°.",
  2: "2. Flexiona quadris 90° com equilíbrio; Mas Desalinha MMII, quadris e tronco; ou Perde neutro coluna.",
  3: "3. Flexiona quadris 90° com equilíbrio; MMII, quadris e tronco alinhados; Coluna neutra.",
};

export const RITMO_LOMBOPELVICO: { [key: number]: string } = {
  0: "0. NÃO realiza OU contra indicação.",
  1: "1. NÃO realiza a sequência correta do RLP E/OU projeta quadril para trás, e NÃO articula bem a coluna.",
  2: "2. Realiza a sequência correta do RLP mas NÃO articula bem a coluna.",
  3: "3. Realiza a sequência correta do RLP E É CAPAZ de articular bem a coluna.",
};

export const ROTACAO_MEDIAL_OMBROS: { [key: number]: string } = {
  0: "0. NÃO atinge lombar alta.",
  1: "1. Só atinge torácica baixa OU não atinge linha média torácica.",
  2: "2. Ultrapassa torácica baixa E atinge linha média, em direção ao ângulo inferior da escápula (T7).",
  3: "3. Ultrapassa o ângulo inferior da escápula E atinge a linha média (acima de T7).",
};

export const ROTACAO_LATERAL_OMBROS: { [key: number]: string } = {
  0: "0. NÃO atinge a cervical.",
  1: "1.  Atinge a cervical OU atinge torácica, porém NÃO atinge linha média.",
  2: "2. Atinge linha média da torácica alta (até T3).",
  3: "3. Atinge linha média da torácica (abaixo de T3).",
};

export const ROTACAO_DE_TRONCO: { [key: number]: string } = {
  0: "0. Faltam três critérios para score 3.",
  1: "1. Faltam dois critérios para score 3.",
  2: "2. Falta um critério para score 3.",
  3: "3. Roda sem movimentar quadril; Movimento ao longo da coluna; Mantém curvaturas; Boa ADM (Cotovelo ultrapassa meio coxa oposta - ADM 90º)",
};

export const SEREIA: { [key: number]: string } = {
  0: "0. Incapaz de realizar a posição (desequilibra)",
  1: "1. Incapaz de manter o ísquio sem sentir desconforto OU eleva muito os ísquios E incapaz de manter a coluna em neutro.",
  2: "2. Capaz de manter o contato bilateral dos ísquios, OU eleva muito pouco os ísquios do solo, mas incapaz de manter a coluna em neutro.",
  3: "3. Capaz de manter o contato bilateral dos ísquios, OU eleva muito pouco os ísquios do solo, mantendo a coluna em neutro.",
};

export const PONTE_UNIPODAL: { [key: number]: string } = {
  0: "0. Incapaz de elevar o quadril do chão realizando extensão de quadril (ADM total).",
  1: '1. Capaz de elevar o quadril do chão E mantê-lo em extensão; Incapaz de retirar um pé do chão e manter o joelho E/OU quadril em extensão por 10"',
  2: '2. Capaz de elevar o quadril do chão E mantê-lo em extensão em apoio unipodal por 10", porém incapaz de manter a pelve alinhada.',
  3: '3. Capaz de manter quadril em extensão E joelho esticado E pelve alinhada em apoio unipodal por 10"',
};

export const STRAIGHT_LEG_RAISE: { [key: number]: string } = {
  0: "0. Faltam três critérios para score 3.",
  1: "1. Faltam dois critérios para score 3.",
  2: "2. Falta um critério para score 3.",
  3: "3. Capaz de flexionar o quadril > 45° E manter coluna neutra E manter joelho esticado.",
};

export const FLEXAO_DE_OMBROS_EM_DD: { [key: number]: string } = {
  0: "0. Dor ao realizar o movimento de flexão dos ombros.",
  1: "1. Incapaz de flexionar completamente os ombros com cotovelos esticados.",
  2: '2. Capaz de flexionar completamente os ombros, NÃO mantém coluna neutra OU costelas "fechadas".',
  3: '3. Capaz de flexionar completamente os ombros mantendo coluna neutra E costelas "fechadas"',
};

export const ROLL_UP: { [key: number]: string } = {
  0: "0. Não tenta OU relata dor OU é contra indicado (hérnia aguda, gestante) OU não sobe.",
  1: "1.  Precisa modificar a execução do movimento (elevação dos pés, impulso).",
  2: "2. Incapaz de articular a coluna totalmente.",
  3: "3. Capaz de articular a coluna totalmente.",
};

export const SIDE_LIFT: { [key: number]: string } = {
  0: "0. Incapaz de posicionar OU elevar o quadril do solo (com joelho flexionado).",
  1: "1. Apoio em joelhos dobrados OU mal realizada; Incapaz de controlar/estabilizar escapula OU power house (girando muito pelve / tronco)",
  2: '2. Controle/estabilidade escapular e power house com apoio em pés; Incapaz de abduzir quadril OU perde o controle/estabilidade ao abduzir por 3"',
  3: '3. Capaz de manter o controle e estabilidade de escapula E Power House; Mantém equilíbrio; Consegue abduzir o quadril superiormente por 3".',
};

export const QUADRUPEDE: { [key: number]: string } = {
  0: "0. Incapaz de elevar braço e perna ao mesmo tempo (3apoios).",
  1: "1. Eleva MI e MS contra-laterais, porém perde neutro de coluna E/OU desalinha quadril E/OU não alinha MS e MI com o tronco.",
  2: '2. Eleva MI e MS contra-laterais com estabilidade de quadril; Coluna neutra em ISOMETRIA 3"; MI, MS e tronco alinhados, porém instável no dinâmico.',
  3: "3. Eleva MI e MS contra-laterais com estabilidade de quadril; Coluna neutra; MI, MS e tronco alinhados no DINÂMICO - 3 repetições",
};

export const LEG_PULL: { [key: number]: string } = {
  0: "0. Incapaz de realizar a posição inicial.",
  1: "1. Realiza a posição inicial, porém não mantém neutro de coluna E/OU desestabiliza cintura escapular.",
  2: "2. Realiza posição inicial estável E capaz de extender quadril; Perde neutro de coluna E/OU desestabiliza cintura escapular E/OU desalinha  quadril.",
  3: "3. Capaz de elevar uma perna, mantendo neutro de coluna E estabilidade de cintura escapular E alinhamento de quadril.",
};

export const EXTENSAO_DE_QUADRIS_EM_DV: { [key: number]: string } = {
  0: "0. Não realiza extensão de quadril.",
  1: "1. Incapaz de elevar a coxa do chão (joelho não ultrapassa a linha do trocanter) com joelho estendido",
  2: "2. Capaz de elevar a coxa do chão (joelho ultrapassa a linha do trocanter) com joelho estendido, porém incapaz de manter coluna neutra.",
  3: "3. Capaz de elevar a coxa do chão (joelho ultrapassa a linha do trocanter) com joelho estendido, mantendo coluna neutra.",
};

export const SWAN: { [key: number]: string } = {
  0: "0. Não tenta OU relata dor OU é contra-indicado (hérnia aguda, gestante).",
  1: "1. Incapaz de realizar a extensão da coluna vertebral até se apoiar no púbis OU movimento predomina no quadril.",
  2: '2. Realiza extensão da coluna vertebral até se apoiar no púbis; Incapaz de distribuir o movimento entre todos os segmentos da coluna (fazer um "C").',
  3: '3. Realiza extensão da coluna vertebral até se apoiar no púbis, distribuindo o movimento entre todos os segmentos da coluna (fazer um "C").',
};

export default () => {
  const { costumerId, date } = useParams();
  const [testesFuncionais, setTestesFuncionais] =
    useState<TestesFuncionaisPilates>();

  useEffect(() => {
    const getTestesFuncionais = async () => {
      if (!costumerId) return;
      const reportRef = doc(db, "report", costumerId);
      const docSnap = await getDoc(reportRef);
      const result = docSnap.data()?.[date!]
        .testesFuncionais as TestesFuncionaisPilates;
      setTestesFuncionais({
        agachamentoBipodal: {
          isometria: result?.agachamentoBipodal?.isometria || 0,
          score: result?.agachamentoBipodal?.score || 0,
          observacoes: result?.agachamentoBipodal?.observacoes || "",
        },
        flexaoQuadrilEmPe: {
          isometria: result?.flexaoQuadrilEmPe?.isometria || 0,
          direito: result?.flexaoQuadrilEmPe?.direito || 0,
          esquerdo: result?.flexaoQuadrilEmPe?.esquerdo || 0,
          observacoes: result?.flexaoQuadrilEmPe?.observacoes || "",
        },
        ritmoLombopelvico: {
          score: result?.ritmoLombopelvico?.score || 0,
          observacoes: result?.ritmoLombopelvico?.observacoes || "",
        },
        rotacaoMedialOmbros: {
          direito: result?.rotacaoMedialOmbros?.direito || 0,
          esquerdo: result?.rotacaoMedialOmbros?.esquerdo || 0,
          observacoes: result?.rotacaoMedialOmbros?.observacoes || "",
        },
        rotacaoLateralOmbros: {
          direito: result?.rotacaoLateralOmbros?.direito || 0,
          esquerdo: result?.rotacaoLateralOmbros?.esquerdo || 0,
          observacoes: result?.rotacaoLateralOmbros?.observacoes || "",
        },
        rotacaoDeTronco: {
          direito: result?.rotacaoDeTronco?.direito || 0,
          esquerdo: result?.rotacaoDeTronco?.esquerdo || 0,
          observacoes: result?.rotacaoDeTronco?.observacoes || "",
        },
        sereia: {
          direito: result?.sereia?.direito || 0,
          esquerdo: result?.sereia?.esquerdo || 0,
          observacoes: result?.sereia?.observacoes || "",
        },
        ponteUnipodal: {
          isometria: result?.ponteUnipodal?.isometria || 0,
          direito: result?.ponteUnipodal?.direito || 0,
          esquerdo: result?.ponteUnipodal?.esquerdo || 0,
          observacoes: result?.ponteUnipodal?.observacoes || "",
        },
        straightLegRaise: {
          direito: result?.straightLegRaise?.direito || 0,
          esquerdo: result?.straightLegRaise?.esquerdo || 0,
          observacoes: result?.straightLegRaise?.observacoes || "",
        },
        flexaoDeOmbrosEmDD: {
          direito: result?.flexaoDeOmbrosEmDD?.direito || 0,
          esquerdo: result?.flexaoDeOmbrosEmDD?.esquerdo || 0,
          observacoes: result?.flexaoDeOmbrosEmDD?.observacoes || "",
        },
        rollUp: {
          score: result?.rollUp?.score || 0,
          observacoes: result?.rollUp?.observacoes || "",
        },
        sideLift: {
          isometria: result?.sideLift?.isometria || 0,
          direito: result?.sideLift?.direito || 0,
          esquerdo: result?.sideLift?.esquerdo || 0,
          observacoes: result?.sideLift?.observacoes || "",
        },
        quadrupede: {
          isometria: result?.quadrupede?.isometria || 0,
          direito: result?.quadrupede?.direito || 0,
          esquerdo: result?.quadrupede?.esquerdo || 0,
          observacoes: result?.quadrupede?.observacoes || "",
        },
        legPull: {
          isometria: result?.legPull?.isometria || 0,
          direito: result?.legPull?.direito || 0,
          esquerdo: result?.legPull?.esquerdo || 0,
          observacoes: result?.legPull?.observacoes || "",
        },
        extensaoDeQuadrisEmDV: {
          direito: result?.extensaoDeQuadrisEmDV?.direito || 0,
          esquerdo: result?.extensaoDeQuadrisEmDV?.esquerdo || 0,
          observacoes: result?.extensaoDeQuadrisEmDV?.observacoes || "",
        },
        swan: {
          score: result?.swan?.score || 0,
          observacoes: result?.swan?.observacoes || "",
        },
      });
    };
    getTestesFuncionais();
  }, []);

  const navigate = useNavigate();

  const updateReport = async () => {
    const reportDoc = doc(db, "report", costumerId!);
    try {
      await setDoc(
        reportDoc,
        {
          [date!]: {
            testesFuncionais: testesFuncionais,
          },
        },
        { merge: true }
      );
      alert("Testes funcionais atualizados com sucesso!");
      navigate(`/editar-ficha/${costumerId}/${date}`);
    } catch (e) {
      alert("Erro ao atualizar os dados dos testes funcionais!");
      console.error(e);
    }
  };

  if (!testesFuncionais)
    return (
      <TabBar selected="Ficha de pacientes">
        <Loading />
      </TabBar>
    );

  return (
    <TabBar selected="Ficha de pacientes">
      <div style={headerStyle}>
        <button type="button" style={buttonStyle} onClick={() => navigate(-1)}>
          Voltar
        </button>
        <h1 style={{ textAlign: "center" }}>
          TESTES FUNCIONAIS PILATES - QUALITATIVO E QUANTITATIVO
        </h1>
        <button
          style={buttonStyle}
          type="button"
          onClick={() => updateReport()}
        >
          Salvar
        </button>
      </div>

      <h3 style={{ color: "white" }}>Agachamento Bipodal:</h3>
      <div style={flexStyle}>
        <div style={inputLabelDivStyle}>
          <label>Isometria (seg)</label>
          <input
            style={inputStyle}
            value={testesFuncionais.agachamentoBipodal.isometria}
            min={0}
            type="number"
            onChange={(k) =>
              setTestesFuncionais({
                ...testesFuncionais,
                agachamentoBipodal: {
                  ...testesFuncionais.agachamentoBipodal,
                  /**@ts-ignore */
                  isometria: k.target.value,
                },
              })
            }
          />
        </div>
        <div style={inputLabelDivStyle}>
          <label>Score:</label>
          <select
            style={inputStyle}
            value={testesFuncionais.agachamentoBipodal.score}
            onChange={(k) =>
              setTestesFuncionais({
                ...testesFuncionais,
                agachamentoBipodal: {
                  ...testesFuncionais.agachamentoBipodal,
                  /**@ts-ignore */
                  score: k.target.value,
                },
              })
            }
          >
            {Object.entries(AGACHAMENTO_BIPODAL).map((value, key) => (
              <option key={key} value={key}>
                {value[1]}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          value={testesFuncionais.agachamentoBipodal.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              agachamentoBipodal: {
                ...testesFuncionais.agachamentoBipodal,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Flexão Quadril em pé:</h3>
      <div style={inputLabelDivStyle}>
        <label>Isometria (seg)</label>
        <input
          style={inputStyle}
          value={testesFuncionais.flexaoQuadrilEmPe.isometria}
          min={0}
          type="number"
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              flexaoQuadrilEmPe: {
                ...testesFuncionais.flexaoQuadrilEmPe,
                /**@ts-ignore */
                isometria: k.target.value,
              },
            })
          }
        />
      </div>
      <div style={inputLabelDivStyle}>
        <label>D:</label>
        <select
          style={inputStyle}
          value={testesFuncionais.flexaoQuadrilEmPe.direito}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              flexaoQuadrilEmPe: {
                ...testesFuncionais.flexaoQuadrilEmPe,
                /**@ts-ignore */
                direito: k.target.value,
              },
            })
          }
        >
          {Object.entries(FLEXAO_QUADRIL_EM_PE).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>E:</label>
        <select
          style={inputStyle}
          value={testesFuncionais.flexaoQuadrilEmPe.esquerdo}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              flexaoQuadrilEmPe: {
                ...testesFuncionais.flexaoQuadrilEmPe,
                /**@ts-ignore */
                esquerdo: k.target.value,
              },
            })
          }
        >
          {Object.entries(FLEXAO_QUADRIL_EM_PE).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          value={testesFuncionais.flexaoQuadrilEmPe.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              flexaoQuadrilEmPe: {
                ...testesFuncionais.flexaoQuadrilEmPe,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Ritmo Lombopélvico</h3>
      <div style={inputLabelDivStyle}>
        <label>Score:</label>
        <select
          style={inputStyle}
          value={testesFuncionais.ritmoLombopelvico.score}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              ritmoLombopelvico: {
                ...testesFuncionais.ritmoLombopelvico,
                /**@ts-ignore */
                score: k.target.value,
              },
            })
          }
        >
          {Object.entries(RITMO_LOMBOPELVICO).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          value={testesFuncionais.ritmoLombopelvico.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              ritmoLombopelvico: {
                ...testesFuncionais.ritmoLombopelvico,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Rotação Medial Ombros:</h3>
      <div style={inputLabelDivStyle}>
        <label>D:</label>
        <select
          style={inputStyle}
          value={testesFuncionais.rotacaoMedialOmbros.direito}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              rotacaoMedialOmbros: {
                ...testesFuncionais.rotacaoMedialOmbros,
                /**@ts-ignore */
                direito: k.target.value,
              },
            })
          }
        >
          {Object.entries(ROTACAO_MEDIAL_OMBROS).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>E:</label>
        <select
          style={inputStyle}
          value={testesFuncionais.rotacaoMedialOmbros.esquerdo}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              rotacaoMedialOmbros: {
                ...testesFuncionais.rotacaoMedialOmbros,
                /**@ts-ignore */
                esquerdo: k.target.value,
              },
            })
          }
        >
          {Object.entries(ROTACAO_MEDIAL_OMBROS).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          value={testesFuncionais.rotacaoMedialOmbros.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              rotacaoMedialOmbros: {
                ...testesFuncionais.rotacaoMedialOmbros,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Rotação Lateral Ombros:</h3>
      <div style={inputLabelDivStyle}>
        <label>D:</label>
        <select
          style={inputStyle}
          value={testesFuncionais.rotacaoLateralOmbros.direito}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              rotacaoLateralOmbros: {
                ...testesFuncionais.rotacaoLateralOmbros,
                /**@ts-ignore */
                direito: k.target.value,
              },
            })
          }
        >
          {Object.entries(ROTACAO_LATERAL_OMBROS).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>E:</label>
        <select
          style={inputStyle}
          value={testesFuncionais.rotacaoLateralOmbros.esquerdo}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              rotacaoLateralOmbros: {
                ...testesFuncionais.rotacaoLateralOmbros,
                /**@ts-ignore */
                esquerdo: k.target.value,
              },
            })
          }
        >
          {Object.entries(ROTACAO_LATERAL_OMBROS).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          value={testesFuncionais.rotacaoLateralOmbros.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              rotacaoLateralOmbros: {
                ...testesFuncionais.rotacaoLateralOmbros,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Rotação de Tronco:</h3>
      <div style={inputLabelDivStyle}>
        <label>D:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.rotacaoDeTronco.direito}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              rotacaoDeTronco: {
                ...testesFuncionais.rotacaoDeTronco,
                /**@ts-ignore */
                direito: k.target.value,
              },
            })
          }
        >
          {Object.entries(ROTACAO_DE_TRONCO).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>E:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.rotacaoDeTronco.esquerdo}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              rotacaoDeTronco: {
                ...testesFuncionais.rotacaoDeTronco,
                /**@ts-ignore */
                esquerdo: k.target.value,
              },
            })
          }
        >
          {Object.entries(ROTACAO_DE_TRONCO).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          value={testesFuncionais.rotacaoDeTronco.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              rotacaoDeTronco: {
                ...testesFuncionais.rotacaoDeTronco,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Sereia:</h3>
      <div style={inputLabelDivStyle}>
        <label>D:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.sereia.direito}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              sereia: {
                ...testesFuncionais.sereia,
                /**@ts-ignore */
                direito: k.target.value,
              },
            })
          }
        >
          {Object.entries(SEREIA).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>E:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.sereia.esquerdo}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              sereia: {
                ...testesFuncionais.sereia,
                /**@ts-ignore */
                esquerdo: k.target.value,
              },
            })
          }
        >
          {Object.entries(SEREIA).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          value={testesFuncionais.sereia.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              sereia: {
                ...testesFuncionais.sereia,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Ponte Unipodal:</h3>
      <div style={inputLabelDivStyle}>
        <label>Isometria (seg)</label>
        <input
          style={inputStyle}
          value={testesFuncionais.ponteUnipodal.isometria}
          min={0}
          type="number"
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              ponteUnipodal: {
                ...testesFuncionais.ponteUnipodal,
                /**@ts-ignore */
                isometria: k.target.value,
              },
            })
          }
        />
      </div>
      <div style={inputLabelDivStyle}>
        <label>D:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.ponteUnipodal.direito}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              ponteUnipodal: {
                ...testesFuncionais.ponteUnipodal,
                /**@ts-ignore */
                direito: k.target.value,
              },
            })
          }
        >
          {Object.entries(PONTE_UNIPODAL).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>E:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.ponteUnipodal.esquerdo}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              ponteUnipodal: {
                ...testesFuncionais.ponteUnipodal,
                /**@ts-ignore */
                esquerdo: k.target.value,
              },
            })
          }
        >
          {Object.entries(PONTE_UNIPODAL).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          value={testesFuncionais.ponteUnipodal.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              ponteUnipodal: {
                ...testesFuncionais.ponteUnipodal,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Straight Leg Raise:</h3>
      <div style={inputLabelDivStyle}>
        <label>D:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.straightLegRaise.direito}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              straightLegRaise: {
                ...testesFuncionais.straightLegRaise,
                /**@ts-ignore */
                direito: k.target.value,
              },
            })
          }
        >
          {Object.entries(STRAIGHT_LEG_RAISE).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>E:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.straightLegRaise.esquerdo}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              straightLegRaise: {
                ...testesFuncionais.straightLegRaise,
                /**@ts-ignore */
                esquerdo: k.target.value,
              },
            })
          }
        >
          {Object.entries(STRAIGHT_LEG_RAISE).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          cols={72}
          value={testesFuncionais.straightLegRaise.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              straightLegRaise: {
                ...testesFuncionais.straightLegRaise,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Flexão de Ombros em DD:</h3>
      <div style={inputLabelDivStyle}>
        <label>D:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.flexaoDeOmbrosEmDD.direito}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              flexaoDeOmbrosEmDD: {
                ...testesFuncionais.flexaoDeOmbrosEmDD,
                /**@ts-ignore */
                direito: k.target.value,
              },
            })
          }
        >
          {Object.entries(FLEXAO_DE_OMBROS_EM_DD).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>E:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.flexaoDeOmbrosEmDD.esquerdo}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              flexaoDeOmbrosEmDD: {
                ...testesFuncionais.flexaoDeOmbrosEmDD,
                /**@ts-ignore */
                esquerdo: k.target.value,
              },
            })
          }
        >
          {Object.entries(FLEXAO_DE_OMBROS_EM_DD).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          value={testesFuncionais.flexaoDeOmbrosEmDD.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              flexaoDeOmbrosEmDD: {
                ...testesFuncionais.flexaoDeOmbrosEmDD,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Roll Up:</h3>
      <div style={inputLabelDivStyle}>
        <label>Score:</label>
        <select
          style={inputStyle}
          value={testesFuncionais.rollUp.score}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              rollUp: {
                ...testesFuncionais.rollUp,
                /**@ts-ignore */
                score: k.target.value,
              },
            })
          }
        >
          {Object.entries(ROLL_UP).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          cols={72}
          value={testesFuncionais.rollUp.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              rollUp: {
                ...testesFuncionais.rollUp,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Side Lift:</h3>
      <div style={inputLabelDivStyle}>
        <label>Isometria (seg)</label>
        <input
          style={inputStyle}
          value={testesFuncionais.sideLift.isometria}
          min={0}
          type="number"
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              sideLift: {
                ...testesFuncionais.sideLift,
                /**@ts-ignore */
                isometria: k.target.value,
              },
            })
          }
        />
      </div>
      <div style={inputLabelDivStyle}>
        <label>D:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.sideLift.direito}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              sideLift: {
                ...testesFuncionais.sideLift,
                /**@ts-ignore */
                direito: k.target.value,
              },
            })
          }
        >
          {Object.entries(SIDE_LIFT).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>E:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.sideLift.esquerdo}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              sideLift: {
                ...testesFuncionais.sideLift,
                /**@ts-ignore */
                esquerdo: k.target.value,
              },
            })
          }
        >
          {Object.entries(SIDE_LIFT).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          value={testesFuncionais.sideLift.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              sideLift: {
                ...testesFuncionais.sideLift,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Quadrupede:</h3>
      <div style={inputLabelDivStyle}>
        <label>Isometria (seg)</label>
        <input
          style={inputStyle}
          value={testesFuncionais.quadrupede.isometria}
          min={0}
          type="number"
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              quadrupede: {
                ...testesFuncionais.quadrupede,
                /**@ts-ignore */
                isometria: k.target.value,
              },
            })
          }
        />
      </div>
      <div style={inputLabelDivStyle}>
        <label>D:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.quadrupede.direito}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              quadrupede: {
                ...testesFuncionais.quadrupede,
                /**@ts-ignore */
                direito: k.target.value,
              },
            })
          }
        >
          {Object.entries(QUADRUPEDE).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>E:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.quadrupede.esquerdo}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              quadrupede: {
                ...testesFuncionais.quadrupede,
                /**@ts-ignore */
                esquerdo: k.target.value,
              },
            })
          }
        >
          {Object.entries(QUADRUPEDE).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          value={testesFuncionais.quadrupede.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              quadrupede: {
                ...testesFuncionais.quadrupede,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Leg Pull:</h3>
      <div style={inputLabelDivStyle}>
        <label>Isometria (seg)</label>
        <input
          style={inputStyle}
          value={testesFuncionais.legPull.isometria}
          min={0}
          type="number"
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              legPull: {
                ...testesFuncionais.legPull,
                /**@ts-ignore */
                isometria: k.target.value,
              },
            })
          }
        />
      </div>
      <div style={inputLabelDivStyle}>
        <label>D:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.legPull.direito}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              legPull: {
                ...testesFuncionais.legPull,
                /**@ts-ignore */
                direito: k.target.value,
              },
            })
          }
        >
          {Object.entries(LEG_PULL).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>E:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.legPull.esquerdo}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              legPull: {
                ...testesFuncionais.legPull,
                /**@ts-ignore */
                esquerdo: k.target.value,
              },
            })
          }
        >
          {Object.entries(LEG_PULL).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          cols={72}
          value={testesFuncionais.legPull.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              legPull: {
                ...testesFuncionais.legPull,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Extensão de Quadris em DV:</h3>
      <div style={inputLabelDivStyle}>
        <label>D:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.extensaoDeQuadrisEmDV.direito}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              extensaoDeQuadrisEmDV: {
                ...testesFuncionais.extensaoDeQuadrisEmDV,
                /**@ts-ignore */
                direito: k.target.value,
              },
            })
          }
        >
          {Object.entries(EXTENSAO_DE_QUADRIS_EM_DV).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>E:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.extensaoDeQuadrisEmDV.esquerdo}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              extensaoDeQuadrisEmDV: {
                ...testesFuncionais.extensaoDeQuadrisEmDV,
                /**@ts-ignore */
                esquerdo: k.target.value,
              },
            })
          }
        >
          {Object.entries(EXTENSAO_DE_QUADRIS_EM_DV).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          value={testesFuncionais.extensaoDeQuadrisEmDV.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              extensaoDeQuadrisEmDV: {
                ...testesFuncionais.extensaoDeQuadrisEmDV,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <Divider />
      <h3 style={{ color: "white" }}>Swan:</h3>
      <div style={inputLabelDivStyle}>
        <label>Score:</label>
        <select
          style={selectStyle}
          value={testesFuncionais.swan.score}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              swan: {
                ...testesFuncionais.swan,
                /**@ts-ignore */
                score: k.target.value,
              },
            })
          }
        >
          {Object.entries(SWAN).map((value, key) => (
            <option key={key} value={key}>
              {value[1]}
            </option>
          ))}
        </select>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Observações:</label>
        <textarea
          style={inputStyle}
          rows={2}
          value={testesFuncionais.swan.observacoes}
          onChange={(k) =>
            setTestesFuncionais({
              ...testesFuncionais,
              swan: {
                ...testesFuncionais.swan,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>

      <Spacer size={32} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 style={{ color: "white" }}>
          <>Score Total: {getTotalValue(testesFuncionais)}</>
        </h3>
        <h3 style={{ color: "white" }}>
          <>Média do score: {getAverage(testesFuncionais)}</>
        </h3>
        <h3 style={{ color: "white" }}>
          <>Nível: {getLevel(testesFuncionais)}</>
        </h3>
      </div>
    </TabBar>
  );
};
