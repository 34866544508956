import { faArrowUp, faBars, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { color } from "../styles/components";
import Spacer from "./Spacer";
import TabBarItem, { TabBarItemAttribute } from "./TabBarItem";
import LogoSpace from "../images/logo-space_branco.png";
import { StyleSheet } from "../utils/types";

interface Props {
  tabBarItems: TabBarItemAttribute[];
  selected: string;
}

export default (props: Props) => {
  const [burgerVisible, setBurgerVisible] = useState(false);

  return (
    <>
      {!burgerVisible && (
        <>
          <div
            style={{ position: "fixed", top: 16, left: 8 }}
            onClick={() => setBurgerVisible(true)}
          >
            <FontAwesomeIcon
              icon={faBars}
              size={"2x"}
              color={color.strongBlue}
            />
          </div>
          <div
            style={{ position: "fixed", bottom: 16, left: 8, opacity: 0.5 }}
            onClick={() => window.scrollTo({ top: 0 })}
          >
            <FontAwesomeIcon
              icon={faArrowUp}
              size={"2x"}
              color={color.softBlue}
            />
          </div>
        </>
      )}
      {burgerVisible && (
        <div
          style={{
            display: "flex",
            position: "fixed",
          }}
        >
          <div style={styles.container}>
            <img src={LogoSpace} style={styles.logo} />

            {props.tabBarItems.map((item) => (
              <TabBarItem
                item={item}
                selected={props.selected === item.name}
                key={item.name}
              />
            ))}
          </div>
          <Spacer size={16} row />
          <FontAwesomeIcon
            icon={faX}
            size={"2x"}
            color={color.strongBlue}
            style={{ paddingTop: 16 }}
            onClick={() => setBurgerVisible(false)}
          />
        </div>
      )}
    </>
  );
};

const styles: StyleSheet = {
  container: {
    width: 300,
    height: "100vh",
    backgroundColor: color.strongBlue,
    opacity: 0.95,
    borderWidth: 2,
    borderRightStyle: "solid",
    borderColor: color.strongBlue,
  },
  logo: {
    width: "50%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
};
