import TabBar from "../components/TabBar";
import { useNavigate, useParams } from "react-router-dom";
import { Costumer } from "./CostumerCRUD";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-config";
import Loading from "../components/Loading";
import { buttonStyle, headerStyle } from "../styles/components";
import Spacer from "../components/Spacer";
import { StyleSheet } from "../utils/types";

export default () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [costumer, setCostumer] = useState<Costumer | undefined>();

  useEffect(() => {
    const getUser = async () => {
      if (!id) return;
      const costumerRef = doc(db, "costumers", id);
      const docSnap = await getDoc(costumerRef);
      setCostumer(docSnap.data() as Costumer);
    };
    getUser();
  }, []);

  if (!costumer)
    return (
      <TabBar selected="Clientes">
        <Loading />
      </TabBar>
    );

  const birthDate = new Date(costumer.birthDate).toLocaleDateString("pt-BR");
  return (
    <TabBar selected="Clientes">
      <div style={headerStyle}>
        <button style={buttonStyle} onClick={() => navigate(-1)}>
          Voltar
        </button>
        <h1 style={{ textAlign: "center" }}>Detalhes do Cliente</h1>
        <button
          style={buttonStyle}
          onClick={() => navigate(`/editar-cliente${id}`)}
        >
          Editar cliente
        </button>
      </div>
      <Spacer size={16} />
      <CostumerField label="Nome" value={costumer.name} />
      <CostumerField label="Email" value={costumer.email} />
      <CostumerField label="Dominância MMSS" value={costumer.dominancy} />
      <CostumerField label="CPF" value={costumer.CPF} />
      <CostumerField label="Endereço" value={costumer.address} />
      <CostumerField label="Data de Nascimento" value={costumer.birthDate} />
      <CostumerField label="Telefone celular" value={costumer.cellPhone} />
      <CostumerField
        label="Telefone de emergência"
        value={costumer.emergencyPhone}
      />
      <CostumerField label="Estado civil" value={costumer.maritalStatus} />
      <CostumerField label="Profissão" value={costumer.occupation} />
    </TabBar>
  );
};

const CostumerField = ({
  label,
  value,
}: {
  label: string;
  value: string | undefined;
}) => {
  return (
    <p style={styles.costumerField}>
      {label}: <b>{value}</b>
    </p>
  );
};

const styles: StyleSheet = {
  costumerField: {
    color: "white",
    fontSize: 18,
    marginTop: 32,
  },
};
