import React from "react";
import { Teste, TestesEspecificos } from "../ReportsCRUD";
import { color } from "../../styles/components";
import ReportItem from "./ReportItem";

export default ({
  testesEspecificos,
}: {
  testesEspecificos?: TestesEspecificos;
}) => {
  if (!testesEspecificos) return null;

  const {
    mobilidade,
    flexibilidade,
    forcaOuFuncaoMuscular,
    testesComplementares,
    outros,
  } = testesEspecificos;

  return (
    <>
      <h2 style={{ backgroundColor: color.softBlue, textAlign: "center" }}>
        Testes Específicos: Mobilidade, Flexibilidade e Força
      </h2>
      <div style={{ paddingLeft: "4px" }}>
        {!!mobilidade?.length && (
          <TesteEspecifico
            title="MOBILIDADE (ATIVA - PASSIVA - END FEEL - PADRÃO CAPSULAR)"
            testResults={mobilidade}
          />
        )}
        {!!flexibilidade?.length && (
          <TesteEspecifico title="FLEXIBILIDADE" testResults={flexibilidade} />
        )}
        {!!forcaOuFuncaoMuscular?.length && (
          <TesteEspecifico
            title="FORÇA/ FUNÇÃO MUSCULAR"
            testResults={forcaOuFuncaoMuscular}
          />
        )}
        {!!testesComplementares?.length && (
          <TesteEspecifico
            title="Testes Complementares"
            testResults={testesComplementares}
          />
        )}
        {outros && <ReportItem title="Outros" description={outros} />}
      </div>
    </>
  );
};

const TesteEspecifico = ({
  title,
  testResults,
}: {
  title: string;
  testResults: Teste[];
}) => {
  return (
    <>
      <h3 style={{ color: color.strongBlue }}>{title}</h3>
      {testResults.map((i) => (
        <p>
          {i.item} - {i.resultado}
        </p>
      ))}
    </>
  );
};
