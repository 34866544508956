import { doc, getDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import TabBar from "../components/TabBar";
import { db } from "../firebase/firebase-config";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  scales,
} from "chart.js";

import {
  buttonStyle,
  color,
  flexStyle,
  headerStyle,
  inputStyle,
} from "../styles/components";
import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateToString } from "../utils/date";
import Loading from "../components/Loading";
import { UserContext } from "../App";
import { Link } from "react-router-dom";
import Spacer from "../components/Spacer";
import { Report, ReportInfo } from "../Reports/ReportsCRUD";
import dayjs from "dayjs";
import ChartResult from "../components/ChartResult";
import {
  getAverage,
  getLevel,
  getMinValue,
  getTotalValue,
} from "../Reports/TestesFuncionaisScore";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

type Results = {
  [key: string]: {
    label: string;
    data: number[];
  };
};

export function getIcon(present: boolean) {
  if (present) return <FontAwesomeIcon icon={faCheck} color="#00b300" />;
  return (
    <FontAwesomeIcon icon={faXmark} color="red" style={{ marginRight: 5 }} />
  );
}

export default () => {
  const employeeId = useContext(UserContext)[0];

  const { id } = useParams();
  const [reports, setReports] = useState<ReportInfo | null>();
  const withTestsReports = reports
    ? Object.entries(reports).filter((item) => !!item[1].testesFuncionais)
    : [];

  const [costumerName, setCostumerName] = useState<string>();

  const navigate = useNavigate();

  useEffect(() => {
    const getUserReports = async () => {
      if (!id) return;
      const reportRef = doc(db, "report", id);
      const docSnap = await getDoc(reportRef);
      if (docSnap?.data() === undefined) {
        setReports(null);
      } else {
        setReports(docSnap.data());
      }
    };

    const getUser = async () => {
      if (!id) return;
      const costumerRef = doc(db, "costumers", id);
      const docSnap = await getDoc(costumerRef);
      setCostumerName(docSnap.data()?.name);
    };

    getUserReports();
    getUser();
  }, []);

  if (reports === undefined) {
    return (
      <TabBar selected="Ficha de Pacientes">
        <Loading />
      </TabBar>
    );
  }

  if (!withTestsReports.length)
    return (
      <TabBar selected="Resultados">
        <div style={headerStyle}>
          <button style={buttonStyle} onClick={() => navigate(-1)}>
            Voltar
          </button>
          <h1>Paciente: {costumerName}</h1>
        </div>
        <p style={{ color: "white" }}>
          Nenhum registro de testes cadastrado para esse paciente.
        </p>
      </TabBar>
    );

  return (
    <TabBar selected="Resultados">
      <div style={headerStyle}>
        <button style={buttonStyle} onClick={() => navigate(-1)}>
          Voltar
        </button>
        <h1 style={{ flex: 1, textAlign: "center" }}>
          Paciente: {costumerName}
        </h1>
      </div>
      <Spacer size={32} />
      <FunctionalTestsResults tests={withTestsReports} />
    </TabBar>
  );
};

function FunctionalTestsResults({ tests }: { tests: [string, Report][] }) {
  const sortedTests = tests.sort((a, b) => {
    const dateA = dayjs(a[0]);
    const dateB = dayjs(b[0]);
    if (dateA.isAfter(dateB)) return 1;
    return -1;
  });

  const data: { [date: string]: { active: boolean; report: Report } } = {};
  sortedTests.map(
    (item) => (data[item[0]] = { active: true, report: item[1] })
  );

  const [testsData, setTestsData] = useState(data);
  const activeTests = Object.entries(testsData).filter(
    (item) => !!item[1].active
  );

  const results: Results = {
    averageScore: { label: "Média do Score", data: [] },
    agachamentoBipodal: { label: "Agachamento bipodal", data: [] },
    flexaoQuadrilEmPe: { label: "Flexão Quadril em Pé", data: [] },
    ritmoLombopelvico: { label: "Ritmo Lombopélvico", data: [] },
    rotacaoMedialOmbros: { label: "Rotação Medial Ombros", data: [] },
    rotacaoLateralOmbros: { label: "Rotação Lateral Ombros", data: [] },
    rotacaoDeTronco: { label: "Rotação de Tronco", data: [] },
    sereia: { label: "Sereia", data: [] },
    ponteUnipodal: { label: "Ponte Unipodal", data: [] },
    straightLegRaise: { label: "Straight Leg Raise", data: [] },
    flexaoDeOmbrosEmDD: { label: "Flexão de Ombros em DD", data: [] },
    rollUp: { label: "Roll Up", data: [] },
    sideLift: { label: "Side Lift", data: [] },
    quadrupede: { label: "Quadrúpede", data: [] },
    legPull: { label: "Leg Pull", data: [] },
    extensaoDeQuadrisEmDV: { label: "Extensão de Quadris em DV", data: [] },
    swan: { label: "Swan", data: [] },
  };

  const totalScore: number[] = [];

  activeTests.map((item) => {
    const testesFuncionais = item[1].report!.testesFuncionais;

    results.averageScore.data.push(getAverage(testesFuncionais));
    !!testesFuncionais && totalScore.push(getTotalValue(testesFuncionais));

    results["agachamentoBipodal"].data.push(
      testesFuncionais?.agachamentoBipodal.score || 0
    );
    results["flexaoQuadrilEmPe"].data.push(
      getMinValue(testesFuncionais?.flexaoQuadrilEmPe)
    );
    results["ritmoLombopelvico"].data.push(
      testesFuncionais?.ritmoLombopelvico.score || 0
    );
    results["rotacaoMedialOmbros"].data.push(
      getMinValue(testesFuncionais?.rotacaoMedialOmbros)
    );
    results["rotacaoLateralOmbros"].data.push(
      getMinValue(testesFuncionais?.rotacaoLateralOmbros)
    );
    results["rotacaoDeTronco"].data.push(
      getMinValue(testesFuncionais?.rotacaoDeTronco)
    );
    results["sereia"].data.push(getMinValue(testesFuncionais?.sereia));
    results["ponteUnipodal"].data.push(
      getMinValue(testesFuncionais?.ponteUnipodal)
    );
    results["straightLegRaise"].data.push(
      getMinValue(testesFuncionais?.straightLegRaise)
    );
    results["flexaoDeOmbrosEmDD"].data.push(
      getMinValue(testesFuncionais?.flexaoDeOmbrosEmDD)
    );
    results["rollUp"].data.push(testesFuncionais?.rollUp.score || 0);
    results["sideLift"].data.push(getMinValue(testesFuncionais?.sideLift));
    results["quadrupede"].data.push(getMinValue(testesFuncionais?.quadrupede));
    results["legPull"].data.push(getMinValue(testesFuncionais?.legPull));
    results["extensaoDeQuadrisEmDV"].data.push(
      getMinValue(testesFuncionais?.extensaoDeQuadrisEmDV)
    );
    results["swan"].data.push(testesFuncionais?.swan.score || 0);
  });

  const dates = activeTests.map((item) => dateToString(item[0]));

  return (
    <>
      <div style={flexStyle}>
        {Object.keys(testsData).map((date) => {
          return (
            <div key={date} style={{ flexDirection: "column" }}>
              <div>
                <input
                  style={inputStyle}
                  type="checkbox"
                  checked={!!testsData[date].active}
                  onChange={() => setTestActive(!testsData[date].active, date)}
                />
                <label>{dateToString(date)}</label>
              </div>
              <label>{getLevel(testsData[date].report.testesFuncionais)}</label>
            </div>
          );
        })}
      </div>

      <ChartResult
        dates={dates}
        data={totalScore}
        title={"Score total"}
        doNotScale
      />
      {Object.values(results).map((item) => (
        <ChartResult
          dates={dates}
          data={item.data}
          title={item.label}
          key={item.label}
        />
      ))}
    </>
  );

  function setTestActive(active: boolean, date: string) {
    setTestsData({
      ...testsData,
      [date]: {
        ...testsData[date],
        active,
      },
    });
  }
}
