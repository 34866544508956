import { TesteDireitoEEsquerdo, TestesFuncionaisPilates } from "./ReportsCRUD";

export function getMinValue(t?: TesteDireitoEEsquerdo) {
  if (!t) return 0;
  return Math.min(Number(t.direito), Number(t.esquerdo));
}

export function getTotalValue(testesFuncionais: TestesFuncionaisPilates) {
  return (
    Number(testesFuncionais.agachamentoBipodal.score) +
    getMinValue(testesFuncionais.flexaoQuadrilEmPe) +
    Number(testesFuncionais.ritmoLombopelvico.score) +
    getMinValue(testesFuncionais.rotacaoMedialOmbros) +
    getMinValue(testesFuncionais.rotacaoLateralOmbros) +
    getMinValue(testesFuncionais.rotacaoDeTronco) +
    getMinValue(testesFuncionais.sereia) +
    getMinValue(testesFuncionais.ponteUnipodal) +
    getMinValue(testesFuncionais.straightLegRaise) +
    getMinValue(testesFuncionais.flexaoDeOmbrosEmDD) +
    Number(testesFuncionais.rollUp.score) +
    getMinValue(testesFuncionais.sideLift) +
    getMinValue(testesFuncionais.quadrupede) +
    getMinValue(testesFuncionais.legPull) +
    getMinValue(testesFuncionais.extensaoDeQuadrisEmDV) +
    Number(testesFuncionais.swan.score)
  );
}

export function getAverage(testesFuncionais?: TestesFuncionaisPilates) {
  if (!testesFuncionais) return 0;

  return Math.round((getTotalValue(testesFuncionais) / 16) * 100) / 100;
}

export function getLevel(testesFuncionais?: TestesFuncionaisPilates) {
  const avg = getAverage(testesFuncionais);
  if (avg < 1.5) return "Baixo";
  if (avg >= 2.6) return "Alto";
  return "Intermediário";
}
