import {
  faArrowRightFromBracket,
  faChartLine,
  faFileInvoice,
  faPeopleLine,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { UserContext } from "../App";
import LogoSpace from "../images/logo-space_branco.png";
import TabBarItem, { TabBarItemAttribute } from "./TabBarItem";
import { StyleSheet } from "../utils/types";
import { color } from "../styles/components";
import Spacer from "./Spacer";
import BurgerMenu from "./BurgerMenu";

interface Props {
  children?: ReactNode;
  selected: string;
}

export default (props: Props) => {
  const [screenWidth, setWidth] = useState(window.innerWidth);

  const setDimension = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenWidth]);

  const [uid, setUid] = useContext(UserContext);
  const navigate = useNavigate();

  const tabBarItens: TabBarItemAttribute[] = [
    {
      name: "Funcionário",
      icon: faUser,
    },
    {
      name: "Clientes",
      icon: faPeopleLine,
      onClick: () => navigate("/"),
    },
    {
      name: "Ficha de pacientes",
      icon: faFileInvoice,
      onClick: () => navigate("/fichas-pacientes"),
    },
    {
      name: "Resultados",
      icon: faChartLine,
      onClick: () => navigate("/resultados"),
    },
    {
      name: "Sair",
      icon: faArrowRightFromBracket,
      onClick: () => {
        if (window.confirm("Deseja realmente sair?")) {
          setUid(undefined);
        }
      },
    },
  ];

  const isMobile = screenWidth < 960;

  return (
    <div style={{ paddingRight: "32px", margin: 0 }}>
      {isMobile ? (
        <BurgerMenu tabBarItems={tabBarItens} selected={props.selected} />
      ) : (
        <div style={styles.container}>
          <Spacer size={32} />
          <img src={LogoSpace} style={styles.logo} />
          <Spacer size={32} />
          <div style={styles.tabBarItensContainer}>
            {tabBarItens.map((item) => (
              <TabBarItem
                item={item}
                selected={props.selected === item.name}
                key={item.name}
              />
            ))}
          </div>
        </div>
      )}
      <div style={{ marginLeft: isMobile ? 48 : "264px" }}>
        {props.children}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    backgroundColor: color.strongBlue,
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "240px",
    position: "fixed",
  },
  tabBarItensContainer: {
    borderWidth: 1,
    borderColor: color.softBlue,
    borderStyle: "none none solid none",
  },
  logo: {
    width: "60%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
};
