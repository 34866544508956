import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import Routes from "./Routes";
import { BrowserRouter } from "react-router-dom";
import Loading from "./components/Loading";

export const UserContext = createContext<
  [string | null | undefined, (uid: string | undefined) => void]
>([undefined, () => null]);

function App() {
  const [uid, setUid] = useState<string | null | undefined>();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setUid(localStorage.getItem("uid"));
    setLoaded(true);
  }, []);

  if (!loaded) return <Loading />;

  return (
    <BrowserRouter>
      <UserContext.Provider value={[uid, (uid) => setUidWithLocalStorage(uid)]}>
        <Routes />
      </UserContext.Provider>
    </BrowserRouter>
  );

  function setUidWithLocalStorage(uid: string | undefined) {
    if (uid) localStorage.setItem("uid", uid);
    else localStorage.removeItem("uid");
    setUid(uid);
  }
}

export default App;
