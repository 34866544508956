import React, { useEffect, useState } from "react";
import { flexStyle } from "../../styles/components";
import LogoSpace from "../../images/logo-space.png";
import { useParams } from "react-router";
import { Report } from "../ReportsCRUD";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import TabBar from "../../components/TabBar";
import Loading from "../../components/Loading";
import Spacer from "../../components/Spacer";
import dayjs from "dayjs";
import AnamneseSummary from "./AnamneseSummary";
import AnalisePosturalSummary from "./AnalisePosturalSummary";
import AnaliseDePadraoDeMovimentoSummary from "./AnaliseDePadraoDeMovimentoSummary";
import TestesEspecificosSummary from "./TestesEspecificosSummary";
import TestesFuncionaisSummary from "./TestesFuncionaisSummary";
import { Costumer } from "../../Costumer/CostumerCRUD";

export default () => {
  const { costumerId, date } = useParams();
  const [report, setReport] = useState<Report>();

  const [costumer, setCostumer] = useState<Costumer>();

  useEffect(() => {
    const getUserReports = async () => {
      if (!costumerId) return;
      const reportRef = doc(db, "report", costumerId);
      const docSnap = await getDoc(reportRef);
      setReport(docSnap.data()![date!]);
    };

    const getUser = async () => {
      if (!costumerId) return;
      const costumerRef = doc(db, "costumers", costumerId);
      const docSnap = await getDoc(costumerRef);

      setCostumer(docSnap.data() as Costumer);
    };

    getUserReports();
    getUser();
  }, []);

  if (!report || !costumer)
    return (
      <TabBar selected="Resultados">
        <Loading />
      </TabBar>
    );

  const age = dayjs(date).diff(dayjs(costumer.birthDate), "year");

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <img src={LogoSpace} width={"150px"} />
        <div style={{ flex: 1 }}>
          <h2>Relatório de Avaliação Fisioterapêutica</h2>
          <div style={flexStyle}>
            <p>
              <b>Paciente: </b>
              {costumer.name}
            </p>
            <Spacer size={8} row />
            <p>
              <b>Idade: </b> {age}
            </p>
            <Spacer size={8} row />
            <p>
              <b>Profissão: </b> {costumer.occupation}
            </p>
            <Spacer size={8} row />
            <p>
              <b>Dominância de MMSS: </b> {costumer.dominancy}
            </p>
            <Spacer size={8} row />
            <p>
              <b>Data da Avaliação: </b> {dayjs(date).format("DD/MM/YYYY")}
            </p>
          </div>
        </div>
      </div>

      <AnamneseSummary anamnese={report?.anamnese} />
      <Spacer size={16} />
      <AnalisePosturalSummary analisePostural={report?.analisePostural} />
      <Spacer size={16} />
      <AnaliseDePadraoDeMovimentoSummary
        padraoDeMovimento={report?.padraoDeMovimento}
      />
      <Spacer size={16} />
      <TestesEspecificosSummary testesEspecificos={report?.testesEspecificos} />
      <TestesFuncionaisSummary testesFuncionais={report?.testesFuncionais} />
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "white",
    padding: "24px",
  },
  header: {
    ...flexStyle,
    alignItems: "center",
  },
};
