import TabBar from "../components/TabBar";
import { useNavigate } from "react-router-dom";
import {
  buttonStyle,
  headerStyle,
  headerTitleStyle,
} from "../styles/components";
import CostumerSelect from "../components/CostumerSelect";
import React from "react";
import Spacer from "../components/Spacer";

export default () => {
  const navigate = useNavigate();

  return (
    <TabBar selected="Clientes">
      <div style={headerStyle}>
        <h1 style={headerTitleStyle}>Clientes</h1>
        <button
          style={buttonStyle}
          onClick={() => {
            navigate("/novo-cliente");
          }}
        >
          Novo cliente
        </button>
      </div>
      <Spacer size={16} />
      <CostumerSelect route="/" />
    </TabBar>
  );
};
