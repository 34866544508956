import React, { useEffect, useState } from "react";
import { flexStyle } from "../styles/components";
import LogoSpace from "../images/logo-space.png";
import { useParams } from "react-router";
import { Report } from "./ReportsCRUD";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-config";
import TabBar from "../components/TabBar";
import Loading from "../components/Loading";
import Spacer from "../components/Spacer";
import dayjs from "dayjs";

export default () => {
  const { costumerId, date } = useParams();
  const [report, setReport] = useState<Report>();
  const [costumerName, setCostumerName] = useState<string>();

  useEffect(() => {
    const getUserReports = async () => {
      if (!costumerId) return;
      const reportRef = doc(db, "report", costumerId);
      const docSnap = await getDoc(reportRef);
      setReport(docSnap.data()![date!]);
    };

    const getUser = async () => {
      if (!costumerId) return;
      const costumerRef = doc(db, "costumers", costumerId);
      const docSnap = await getDoc(costumerRef);
      setCostumerName(docSnap.data()?.name);
    };

    getUserReports();
    getUser();
  }, []);

  if (!report || !costumerName)
    return (
      <TabBar selected="Resultados">
        <Loading />
      </TabBar>
    );

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <img src={LogoSpace} width={"150px"} />
        <div>
          <h2>Diagnóstico de Avaliação Fisioterapêutica</h2>
          <div style={flexStyle}>
            <p>
              <b>Paciente: </b>
              {costumerName}
            </p>
            <Spacer size={8} row />
            {/* <p>
              <b>Fisioterapeuta: </b> Teste
            </p> */}
            <p>
              <b>Data da Avaliação: </b> {dayjs(date).format("DD/MM/YYYY")}
            </p>
          </div>
        </div>
      </div>
      <h3>Diagnóstico fisioterapêutico:</h3>
      <p style={{ whiteSpace: "pre-line" }}>
        {report.diagnostico.diagnosticoFisioterapeutico}
      </p>
      <h3>Objetivos do Tratamento Fisioterapêutico:</h3>
      <p style={{ whiteSpace: "pre-line" }}>
        {report.diagnostico.objetivosDoTratamentoFisioterapeutico}
      </p>
    </div>
  );
};

const styles = {
  container: {
    height: "100vh",
    backgroundColor: "white",
    padding: "24px",
  },
  header: {
    ...flexStyle,
    alignItems: "center",
  },
};
