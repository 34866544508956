import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import TabBar from "../components/TabBar";
import { db } from "../firebase/firebase-config";
import {
  buttonStyle,
  color,
  flexStyle,
  headerStyle,
  inputLabelDivStyle,
} from "../styles/components";
import { Report } from "./ReportsCRUD";
import { inputDivStyle, inputStyle } from "../styles/components";
import Loading from "../components/Loading";
import { getIcon } from "./CostumerReports";
import Spacer from "../components/Spacer";
import { Link } from "react-router-dom";

export default () => {
  const { costumerId, date } = useParams();
  const [report, setReport] = useState<Report>();
  const [costumerName, setCostumerName] = useState<string>();
  const [formattedDate, setFormattedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const navigate = useNavigate();

  useEffect(() => {
    const getUserReports = async () => {
      if (!costumerId) return;
      const reportRef = doc(db, "report", costumerId);
      const docSnap = await getDoc(reportRef);
      setReport(docSnap.data()![date!]);
    };

    const getUser = async () => {
      if (!costumerId) return;
      const costumerRef = doc(db, "costumers", costumerId);
      const docSnap = await getDoc(costumerRef);
      setCostumerName(docSnap.data()?.name);
    };

    getUserReports();
    getUser();
  }, []);

  useEffect(() => {
    if (report?.date) setFormattedDate(report.date);
  }, [report]);

  if (!report || !costumerName)
    return (
      <TabBar selected="Ficha de pacientes">
        <Loading />
      </TabBar>
    );

  return (
    <TabBar selected="Ficha de pacientes">
      <div style={headerStyle}>
        <button style={buttonStyle} onClick={() => navigate(-1)}>
          Voltar
        </button>
        <h1>Paciente: {costumerName}</h1>
        <button style={buttonStyle}>Salvar</button>
      </div>
      <Spacer size={32} />
      <div style={inputLabelDivStyle}>
        <label>Data do exame:</label>
        <input
          style={{ ...inputStyle, maxWidth: "512px" }}
          type="date"
          value={formattedDate}
          onChange={(k) => setFormattedDate(k.target.value)}
        />
      </div>
      <Spacer size={16} />
      <div
        style={styles.reportItem}
        onClick={() => navigate(`/anamnese/${costumerId}/${date}`)}
      >
        <label style={styles.label}>Anamnese</label>
        {getIcon(!!report?.anamnese)}
      </div>
      <Spacer size={16} />
      <div
        style={styles.reportItem}
        onClick={() => navigate(`/analise-postural/${costumerId}/${date}`)}
      >
        <label style={styles.label}>Análise Postural</label>
        {getIcon(!!report?.analisePostural)}
      </div>
      <Spacer size={16} />
      <div
        style={styles.reportItem}
        onClick={() =>
          navigate(`/analise-do-padrao-de-movimento/${costumerId}/${date}`)
        }
      >
        <label style={styles.label}>Análise do Padrão de Movimento</label>
        {getIcon(!!report?.padraoDeMovimento)}
      </div>
      <Spacer size={16} />
      <div
        style={styles.reportItem}
        onClick={() => navigate(`/testes-especificos/${costumerId}/${date}`)}
      >
        <label style={styles.label}>
          Testes Específicos: Mobilidade, Flexibilidade e Força
        </label>
        {getIcon(!!report?.testesEspecificos)}
      </div>
      <Spacer size={16} />
      <div
        style={styles.reportItem}
        onClick={() =>
          navigate(`/testes-funcionais-pilates/${costumerId}/${date}`)
        }
      >
        <label style={styles.label}>Testes Funcionais do Pilates</label>
        {getIcon(!!report?.testesFuncionais)}
      </div>
      <Spacer size={16} />
      <div
        style={styles.reportItem}
        onClick={() => navigate(`/diagnostico/${costumerId}/${date}`)}
      >
        <label style={styles.label}>
          Diagnóstico Fisioterapêutico e Objetivos
        </label>
        {getIcon(!!report?.diagnostico)}
      </div>
    </TabBar>
  );
};

const styles = {
  reportItem: {
    backgroundColor: color.softBlue,
    padding: 16,
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "512px",
  },
  label: { color: "black", marginLeft: 5 },
};
