import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { color } from "../styles/components";
import Spacer from "./Spacer";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

interface Props {
  title: string;
  dates: string[];
  data: number[];
  doNotScale?: boolean;
}

export default (props: Props) => {
  const chartData = {
    labels: props.dates,
    datasets: [
      {
        labels: props.title,
        data: props.data,
        pointBackgroundColor: getChartColor(),
      },
    ],
  };

  return (
    <>
      <h3 style={{ color: "white" }}>{props.title}</h3>
      <Line
        data={chartData}
        height={40}
        options={{
          borderColor: getChartColor(),
          scales: {
            y: {
              min: props.doNotScale ? undefined : 0,
              max: props.doNotScale ? undefined : 3,
              ticks: {
                stepSize: 1,
                color: color.softBlue,
              },
            },
            x: {
              ticks: {
                color: color.strongBlue,
              },
            },
          },
        }}
      />
      <Spacer size={16} />
    </>
  );

  function getChartColor() {
    const data = props.data;
    const lastItem = !!data.length ? data[data.length - 1] : undefined;
    const isWorse = lastItem === undefined ? false : props.data[0] > lastItem;
    const isEqual = lastItem === undefined ? false : props.data[0] === lastItem;

    if (isWorse) return color.error;

    return isEqual ? color.warning : color.success;
  }
};
