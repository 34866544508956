import { doc, setDoc } from "firebase/firestore";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { UserContext } from "../App";
import Loading from "../components/Loading";
import TabBar from "../components/TabBar";
import { db } from "../firebase/firebase-config";
import {
  buttonStyle,
  headerStyle,
  inputDivStyle,
  inputStyle,
} from "../styles/components";

export default () => {
  const employeeId = useContext(UserContext)[0];
  const { id } = useParams();
  const navigate = useNavigate();
  const today = new Date();

  const [date, setDate] = useState(today.toISOString().split("T")[0]);

  if (!id) return <Loading />;

  return (
    <TabBar selected="Ficha de pacientes">
      <div style={{ position: "absolute", left: "22%", right: "2%" }}>
        <div style={headerStyle}>
          <button
            type="button"
            style={buttonStyle}
            onClick={() => navigate(-1)}
          >
            Voltar
          </button>
          <h1>Cadastrar nova ficha</h1>
          <button style={buttonStyle} type="button" onClick={onSubmit}>
            Salvar
          </button>
        </div>
        <div style={inputDivStyle}>
          <label>Data do exame:</label>
          <br />
          <input
            style={inputStyle}
            type="date"
            value={date}
            onChange={(k) => setDate(k.target.value)}
          />
        </div>
      </div>
    </TabBar>
  );

  async function onSubmit() {
    try {
      const reportDoc = doc(db, "report", id!);
      await setDoc(
        reportDoc,
        {
          [date]: {
            employeeId: employeeId,
          },
        },
        { merge: true }
      );
      alert("Relatório criado com sucesso!");
      navigate(`/fichas-paciente${id}`);
    } catch (e) {
      alert("Falha ao criar relatório!");
      console.log(e);
    }
  }
};
