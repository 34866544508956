import React from "react";
import { AnaliseDePadraoDeMovimento } from "../ReportsCRUD";
import ReportItem from "./ReportItem";
import { color } from "../../styles/components";

export default ({
  padraoDeMovimento,
}: {
  padraoDeMovimento?: AnaliseDePadraoDeMovimento;
}) => {
  if (!padraoDeMovimento) return null;

  return (
    <>
      <h2 style={{ backgroundColor: color.softBlue, textAlign: "center" }}>
        Análise do Padrão de Movimento
      </h2>
      <div style={{ paddingLeft: "4px" }}>
        {padraoDeMovimento.ritmoEscapuloumeral && (
          <ReportItem
            title="Ritmo Escapuloumeral"
            description={padraoDeMovimento.ritmoEscapuloumeral}
          />
        )}
        {padraoDeMovimento.ritmoLombopelvico && (
          <ReportItem
            title="Ritmo Lombopélvico"
            description={padraoDeMovimento.ritmoLombopelvico}
          />
        )}
        {padraoDeMovimento.inclinacaoLateralDeTronco && (
          <ReportItem
            title="Inclinação Lateral de tronco"
            description={padraoDeMovimento.inclinacaoLateralDeTronco}
          />
        )}
        {padraoDeMovimento.rotacaoDeTronco && (
          <ReportItem
            title="Rotação de tronco"
            description={padraoDeMovimento.rotacaoDeTronco}
          />
        )}
        {padraoDeMovimento.extensaoDeTronco && (
          <ReportItem
            title="Extensão de tronco"
            description={padraoDeMovimento.extensaoDeTronco}
          />
        )}
        {padraoDeMovimento.quadrupede && (
          <ReportItem
            title="Quadrúpede / Prancha Frontal"
            description={padraoDeMovimento.quadrupede}
          />
        )}
        {padraoDeMovimento.pranchaLateral && (
          <ReportItem
            title="Prancha Lateral"
            description={padraoDeMovimento.pranchaLateral}
          />
        )}
        {padraoDeMovimento.ponteComExtensaoUnilateralDeJoelho && (
          <ReportItem
            title="Ponte com Extensão Unilateral de Joelho"
            description={padraoDeMovimento.ponteComExtensaoUnilateralDeJoelho}
          />
        )}
        {padraoDeMovimento.agachamentoBipodal && (
          <ReportItem
            title="Agachamento Bipodal / Sentar e Levantar"
            description={padraoDeMovimento.agachamentoBipodal}
          />
        )}
        {padraoDeMovimento.agachamentoUnipodal && (
          <ReportItem
            title="Agachamento Unipodal / Step Down Test"
            description={padraoDeMovimento.agachamentoUnipodal}
          />
        )}
        {padraoDeMovimento.caminhadaOuCorrida && (
          <ReportItem
            title="Caminhada / Corrida"
            description={padraoDeMovimento.caminhadaOuCorrida}
          />
        )}
        {padraoDeMovimento.saltoBipodalOuUnipodal && (
          <ReportItem
            title="Salto Bipodal / Salto Unipodal"
            description={padraoDeMovimento.saltoBipodalOuUnipodal}
          />
        )}
        {padraoDeMovimento.outros && (
          <ReportItem title="Outros" description={padraoDeMovimento.outros} />
        )}
      </div>
    </>
  );
};
