import React, { useContext } from "react";
import { Route, Routes, Navigate, Link } from "react-router-dom";
import { UserContext } from "./App";
import CostumerDetails from "./Costumer/CostumerDetails";
import CostumerList from "./Costumer/CostumerList";
import CostumerRegister from "./Costumer/CostumerRegister";
import Login from "./Login/Login";
import AnaliseDoPadraoDeMovimento from "./Reports/AnaliseDoPadraoDeMovimento";
import AnalisePostural from "./Reports/AnalisePostural";
import Anamnese from "./Reports/Anamnese";
import CostumerReports from "./Reports/CostumerReports";
import NewReport from "./Reports/NewReport";
import ReportEdit from "./Reports/ReportEdit";
import ReportsList from "./Reports/ReportsList";
import ReportSummary from "./Reports/Summary/ReportSummary";
import TestesEspecificos from "./Reports/TestesEspecificos";
import TestesFuncionais from "./Reports/TestesFuncionais";
import Diagnostico from "./Reports/Diagnostico";
import ReportDiagnosis from "./Reports/ReportDiagnosis";
import ResultsCostumersList from "./Results/ResultsCostumersList";
import CostumerResults from "./Results/CostumerResults";

export default function MainRoutes() {
  const [user, _] = useContext(UserContext);

  return (
    <Routes>
      {user ? (
        <>
          <Route path="/login" element={<Navigate to="/" replace />} />
          <Route path="/" element={<CostumerList />} />
          <Route path="/novo-cliente" element={<CostumerRegister />} />
          <Route path="/editar-cliente:id" element={<CostumerRegister />} />

          <Route path="/fichas-pacientes" element={<ReportsList />} />
          <Route path="/fichas-paciente:id" element={<CostumerReports />} />
          <Route path="/nova-ficha:id" element={<NewReport />} />
          <Route
            path="/editar-ficha/:costumerId/:date"
            element={<ReportEdit />}
          />
          <Route path="/anamnese/:costumerId/:date" element={<Anamnese />} />
          <Route
            path="/analise-postural/:costumerId/:date"
            element={<AnalisePostural />}
          />
          <Route
            path="/analise-do-padrao-de-movimento/:costumerId/:date"
            element={<AnaliseDoPadraoDeMovimento />}
          />
          <Route
            path="/testes-especificos/:costumerId/:date"
            element={<TestesEspecificos />}
          />
          <Route
            path="/testes-funcionais-pilates/:costumerId/:date"
            element={<TestesFuncionais />}
          />
          <Route
            path="/diagnostico/:costumerId/:date"
            element={<Diagnostico />}
          />
          <Route
            path="/relatorio-diagnostico/:costumerId/:date"
            element={<ReportDiagnosis />}
          />
          <Route
            path="/relatorio/:costumerId/:date"
            element={<ReportSummary />}
          />

          <Route path=":id" element={<CostumerDetails />} />

          <Route path="/resultados" element={<ResultsCostumersList />} />
          <Route path="/analise-resultados/:id" element={<CostumerResults />} />
        </>
      ) : (
        <>
          <Route path="*" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
        </>
      )}
    </Routes>
  );
}
