import loading from "../images/loading.svg";

export default () => {
  return (
    <div
      style={{
        display: "flex",
        width: "70vw",
        height: "80vh",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={loading} alt="loading..." style={{ width: 50 }} />;
    </div>
  );
};
