import React from "react";
import TabBar from "../components/TabBar";
import CostumerSelect from "../components/CostumerSelect";
import { headerStyle, headerTitleStyle } from "../styles/components";

export default () => {
  return (
    <TabBar selected="Ficha de pacientes">
      <div style={headerStyle}>
        <h1 style={headerTitleStyle}>Pesquisar fichas</h1>
      </div>
      <CostumerSelect
        route="/fichas-paciente"
        searchLabel="Selecione o cliente:"
      />
    </TabBar>
  );
};
