import React from "react";
import {
  TesteDireitoEEsquerdo,
  TesteUnilateral,
  TestesFuncionaisPilates,
} from "../ReportsCRUD";
import { color } from "../../styles/components";
import {
  AGACHAMENTO_BIPODAL,
  EXTENSAO_DE_QUADRIS_EM_DV,
  FLEXAO_DE_OMBROS_EM_DD,
  FLEXAO_QUADRIL_EM_PE,
  LEG_PULL,
  PONTE_UNIPODAL,
  QUADRUPEDE,
  RITMO_LOMBOPELVICO,
  ROLL_UP,
  ROTACAO_DE_TRONCO,
  ROTACAO_LATERAL_OMBROS,
  ROTACAO_MEDIAL_OMBROS,
  SEREIA,
  SIDE_LIFT,
  STRAIGHT_LEG_RAISE,
  SWAN,
} from "../TestesFuncionais";
import Divider from "../../components/Divider";
import Spacer from "../../components/Spacer";
import { getAverage, getLevel, getTotalValue } from "../TestesFuncionaisScore";

export default ({
  testesFuncionais,
}: {
  testesFuncionais?: TestesFuncionaisPilates;
}) => {
  if (!testesFuncionais) return null;

  return (
    <div>
      <h2 style={{ backgroundColor: color.softBlue, textAlign: "center" }}>
        Testes Funcionais do Pilates - Qualitativo e Quantitativo
      </h2>
      <div style={{ paddingLeft: "4px" }}>
        {testesFuncionais.agachamentoBipodal && (
          <UnisideTest
            item={testesFuncionais.agachamentoBipodal}
            title="Agachamento Bipodal"
            scoreDefinition={
              AGACHAMENTO_BIPODAL[testesFuncionais.agachamentoBipodal.score]
            }
          />
        )}
        {testesFuncionais.flexaoQuadrilEmPe && (
          <DoubleSideTest
            item={testesFuncionais.flexaoQuadrilEmPe}
            title="Flexão Quadril em pé"
            scoreDefinitionLeft={
              FLEXAO_QUADRIL_EM_PE[testesFuncionais.flexaoQuadrilEmPe.esquerdo]
            }
            scoreDefinitionRight={
              FLEXAO_QUADRIL_EM_PE[testesFuncionais.flexaoQuadrilEmPe.direito]
            }
          />
        )}
        {testesFuncionais.ritmoLombopelvico && (
          <UnisideTest
            item={testesFuncionais.ritmoLombopelvico}
            title="Ritmo Lombopélvico"
            scoreDefinition={
              RITMO_LOMBOPELVICO[testesFuncionais.ritmoLombopelvico.score]
            }
          />
        )}
        {testesFuncionais.rotacaoMedialOmbros && (
          <DoubleSideTest
            item={testesFuncionais.rotacaoMedialOmbros}
            title="Rotação Medial Ombros"
            scoreDefinitionLeft={
              ROTACAO_MEDIAL_OMBROS[
                testesFuncionais.rotacaoMedialOmbros.esquerdo
              ]
            }
            scoreDefinitionRight={
              ROTACAO_MEDIAL_OMBROS[
                testesFuncionais.rotacaoMedialOmbros.direito
              ]
            }
          />
        )}
        {testesFuncionais.rotacaoLateralOmbros && (
          <DoubleSideTest
            item={testesFuncionais.rotacaoLateralOmbros}
            title="Rotação Lateral Ombros"
            scoreDefinitionLeft={
              ROTACAO_LATERAL_OMBROS[
                testesFuncionais.rotacaoLateralOmbros.esquerdo
              ]
            }
            scoreDefinitionRight={
              ROTACAO_LATERAL_OMBROS[
                testesFuncionais.rotacaoLateralOmbros.direito
              ]
            }
          />
        )}
        {testesFuncionais.rotacaoDeTronco && (
          <DoubleSideTest
            item={testesFuncionais.rotacaoDeTronco}
            title="Rotação de Tronco"
            scoreDefinitionLeft={
              ROTACAO_DE_TRONCO[testesFuncionais.rotacaoDeTronco.esquerdo]
            }
            scoreDefinitionRight={
              ROTACAO_DE_TRONCO[testesFuncionais.rotacaoDeTronco.direito]
            }
          />
        )}
        {testesFuncionais.sereia && (
          <DoubleSideTest
            item={testesFuncionais.sereia}
            title="Sereia"
            scoreDefinitionLeft={SEREIA[testesFuncionais.sereia.esquerdo]}
            scoreDefinitionRight={SEREIA[testesFuncionais.sereia.direito]}
          />
        )}
        {testesFuncionais.ponteUnipodal && (
          <DoubleSideTest
            item={testesFuncionais.ponteUnipodal}
            title="Ponte Unipodal"
            scoreDefinitionLeft={
              PONTE_UNIPODAL[testesFuncionais.ponteUnipodal.esquerdo]
            }
            scoreDefinitionRight={
              PONTE_UNIPODAL[testesFuncionais.ponteUnipodal.direito]
            }
          />
        )}
        {testesFuncionais.straightLegRaise && (
          <DoubleSideTest
            item={testesFuncionais.straightLegRaise}
            title="Straight Leg Raise"
            scoreDefinitionLeft={
              STRAIGHT_LEG_RAISE[testesFuncionais.straightLegRaise.esquerdo]
            }
            scoreDefinitionRight={
              STRAIGHT_LEG_RAISE[testesFuncionais.straightLegRaise.direito]
            }
          />
        )}
        {testesFuncionais.flexaoDeOmbrosEmDD && (
          <DoubleSideTest
            item={testesFuncionais.flexaoDeOmbrosEmDD}
            title="Flexão de Ombros em DD"
            scoreDefinitionLeft={
              FLEXAO_DE_OMBROS_EM_DD[
                testesFuncionais.flexaoDeOmbrosEmDD.esquerdo
              ]
            }
            scoreDefinitionRight={
              FLEXAO_DE_OMBROS_EM_DD[
                testesFuncionais.flexaoDeOmbrosEmDD.direito
              ]
            }
          />
        )}
        {testesFuncionais.rollUp && (
          <UnisideTest
            item={testesFuncionais.rollUp}
            title="Roll Up"
            scoreDefinition={ROLL_UP[testesFuncionais.rollUp.score]}
          />
        )}
        {testesFuncionais.sideLift && (
          <DoubleSideTest
            item={testesFuncionais.sideLift}
            title="Side Lift"
            scoreDefinitionLeft={SIDE_LIFT[testesFuncionais.sideLift.esquerdo]}
            scoreDefinitionRight={SIDE_LIFT[testesFuncionais.sideLift.direito]}
          />
        )}
        {testesFuncionais.quadrupede && (
          <DoubleSideTest
            item={testesFuncionais.quadrupede}
            title="Quadrúpede"
            scoreDefinitionLeft={
              QUADRUPEDE[testesFuncionais.quadrupede.esquerdo]
            }
            scoreDefinitionRight={
              QUADRUPEDE[testesFuncionais.quadrupede.direito]
            }
          />
        )}
        {testesFuncionais.legPull && (
          <DoubleSideTest
            item={testesFuncionais.legPull}
            title="Leg Pull"
            scoreDefinitionLeft={LEG_PULL[testesFuncionais.legPull.esquerdo]}
            scoreDefinitionRight={LEG_PULL[testesFuncionais.legPull.direito]}
          />
        )}
        {testesFuncionais.extensaoDeQuadrisEmDV && (
          <DoubleSideTest
            item={testesFuncionais.extensaoDeQuadrisEmDV}
            title="Extensão de Quadris em DV"
            scoreDefinitionLeft={
              EXTENSAO_DE_QUADRIS_EM_DV[
                testesFuncionais.extensaoDeQuadrisEmDV.esquerdo
              ]
            }
            scoreDefinitionRight={
              EXTENSAO_DE_QUADRIS_EM_DV[
                testesFuncionais.extensaoDeQuadrisEmDV.direito
              ]
            }
          />
        )}
        {testesFuncionais.swan && (
          <UnisideTest
            item={testesFuncionais.swan}
            title="Swan"
            scoreDefinition={SWAN[testesFuncionais.swan.score]}
          />
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>
            <b>Score Total:</b> {getTotalValue(testesFuncionais)}
          </p>
          <p>
            <b>Média do score:</b> {getAverage(testesFuncionais)}
          </p>
          <p>
            <b>Nível:</b> {getLevel(testesFuncionais)}
          </p>
        </div>
      </div>
    </div>
  );
};

function UnisideTest({
  item,
  title,
  scoreDefinition,
}: {
  item: TesteUnilateral;
  title: string;
  scoreDefinition: string;
}) {
  return (
    <>
      <h3 style={{ color: color.strongBlue }}>{title}:</h3>
      <p>
        <b>Isometria (seg): </b> {item.isometria || 0}
      </p>
      <p>
        <b>Score: </b> {scoreDefinition}
      </p>
      <p>
        <b>Observações: </b> {item.observacoes || "-"}
      </p>
      <Divider />
    </>
  );
}

function DoubleSideTest({
  item,
  title,
  scoreDefinitionRight,
  scoreDefinitionLeft,
}: {
  item: TesteDireitoEEsquerdo;
  title: string;
  scoreDefinitionRight: string;
  scoreDefinitionLeft: string;
}) {
  return (
    <>
      <h3 style={{ color: color.strongBlue }}>{title}:</h3>
      <p>
        <b>Isometria (seg): </b> {item.isometria || 0}
      </p>
      <h4>Score:</h4>
      <p> Direito: {scoreDefinitionRight}</p>
      <p> Esquerdo: {scoreDefinitionLeft}</p>
      <p>
        <b>Observações: </b> {item.observacoes || "-"}
      </p>
      <Divider />
    </>
  );
}
