import React, { useEffect, useState } from "react";
import { db } from "../firebase/firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { color, inputStyle } from "../styles/components";
import Loading from "../components/Loading";
import { Costumer } from "../Costumer/CostumerCRUD";
import { StyleSheet } from "../utils/types";
import Spacer from "./Spacer";

interface CostumerInfo extends Costumer {
  id: string;
}

export default ({
  route,
  searchLabel,
}: {
  route: string;
  searchLabel?: string;
}) => {
  const [costumers, setCostumers] = useState<CostumerInfo[]>([]);
  const costumersCollectionRef = collection(db, "costumers");
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const getcostumers = async () => {
      const data = await getDocs(costumersCollectionRef);
      const unorderedCostumers = data.docs.map((doc) => ({
        ...(doc.data() as Costumer),
        id: doc.id,
      }));
      setCostumers(
        unorderedCostumers.sort((a, b) => a.name.localeCompare(b.name))
      );
    };

    getcostumers();
  }, []);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <label style={{ color: "white" }}>{searchLabel || "Pesquisar:"}</label>
        <Spacer size={10} row />
        <input
          type="text"
          value={search}
          onChange={(k) => setSearch(k.target.value)}
          style={{ ...inputStyle, flex: 1 }}
        />
      </div>
      {costumers.length === 0 ? <Loading /> : null}
      <div>
        {costumers
          .filter((element) => {
            const searchLower = search.toLowerCase();
            return (
              element?.name?.toLowerCase().match(searchLower) ||
              element?.email?.toLowerCase().match(searchLower)
            );
          })
          .map((user) => (
            <div
              style={styles.card}
              onClick={() => navigate(`${route}${user.id}`)}
            >
              <h4>{user.name}</h4>
              <p>{user.email}</p>
            </div>
          ))}
      </div>
    </>
  );
};

const styles: StyleSheet = {
  card: {
    backgroundColor: color.softBlue,
    marginTop: 16,
    marginBottom: 16,
    padding: 16,
    borderRadius: 10,
  },
};
