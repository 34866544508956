import React from "react";
import { Anamnese, Ritmo } from "../ReportsCRUD";
import ReportItem from "./ReportItem";
import { color } from "../../styles/components";
import Spacer from "../../components/Spacer";
import { RITMO_DA_DOR_LABEL } from "../Anamnese";
import { toLocaleNumber } from "../../utils/number";

export default ({ anamnese }: { anamnese?: Anamnese }) => {
  if (!anamnese) return null;

  const atividadesFisicas =
    anamnese.fatoresContextuais?.historicoDeAtividadesFisicas;

  return (
    <div>
      <h2 style={{ backgroundColor: color.softBlue, textAlign: "center" }}>
        Anamnese
      </h2>
      <div style={{ paddingLeft: "4px" }}>
        {anamnese.posturaDuranteODia && (
          <ReportItem
            title="Postura durante o dia"
            description={anamnese.posturaDuranteODia}
          />
        )}
        {anamnese.objetivosNaClinica && (
          <ReportItem
            title="Objetivos na Clínica"
            description={anamnese.objetivosNaClinica}
          />
        )}
        {anamnese.diagnosticoMedico && (
          <ReportItem
            title="Diagnóstico Médico"
            description={anamnese.diagnosticoMedico}
          />
        )}
        {anamnese.queixaPrincipal && (
          <ReportItem
            title="Queixa Principal"
            description={anamnese.queixaPrincipal}
          />
        )}
        <Spacer size={8} />
        {anamnese.funcionalidadeEIncapacidade && (
          <>
            <h2 style={{ color: color.strongBlue }}>
              História da Queixa Atual
            </h2>
            <h3 style={{ color: color.strongBlue }}>
              Alterações em estrutura e função
            </h3>
            <div style={{ paddingLeft: "4px" }}>
              {anamnese.funcionalidadeEIncapacidade.localizacaoDaDor && (
                <ReportItem
                  title="Localização da dor e estruturas acometidas"
                  description={
                    anamnese.funcionalidadeEIncapacidade.localizacaoDaDor
                  }
                />
              )}
              {anamnese.funcionalidadeEIncapacidade.inicioDaDor && (
                <ReportItem
                  title="Início da dor"
                  description={anamnese.funcionalidadeEIncapacidade.inicioDaDor}
                />
              )}
              {anamnese.funcionalidadeEIncapacidade.duracaoDaDor && (
                <ReportItem
                  title="Duração da dor"
                  description={
                    anamnese.funcionalidadeEIncapacidade.duracaoDaDor
                  }
                />
              )}
              {anamnese.funcionalidadeEIncapacidade.ritmoDaDor && (
                <ReportItem
                  title="Ritmo da Dor"
                  description={
                    RITMO_DA_DOR_LABEL.label[
                      anamnese.funcionalidadeEIncapacidade.ritmoDaDor
                    ]
                  }
                />
              )}
              <p>
                <b>Irradiação da dor:</b>{" "}
                {anamnese.funcionalidadeEIncapacidade.irradiacaoDaDor?.presente
                  ? "Sim"
                  : "Não"}
              </p>
              {anamnese.funcionalidadeEIncapacidade.irradiacaoDaDor?.regiao && (
                <div style={{ paddingLeft: "4px" }}>
                  <ReportItem
                    title="Região"
                    description={
                      anamnese.funcionalidadeEIncapacidade.irradiacaoDaDor
                        .regiao
                    }
                  />
                </div>
              )}
              {anamnese.funcionalidadeEIncapacidade.intensidade !==
                undefined && (
                <ReportItem
                  title="Intensidade: EVA"
                  description={
                    anamnese.funcionalidadeEIncapacidade.intensidade + ""
                  }
                />
              )}
              {anamnese.funcionalidadeEIncapacidade.fatoresQuePioramADor
                ?.presente && (
                <ReportItem
                  title="Fatores, movimentos ou posições que pioram a queixa"
                  description={
                    anamnese.funcionalidadeEIncapacidade.fatoresQuePioramADor
                      .descricao
                  }
                />
              )}
              {anamnese.funcionalidadeEIncapacidade.fatoresQueMelhoramADor
                ?.presente && (
                <ReportItem
                  title="Fatores, movimentos ou posições que melhoram a queixa"
                  description={
                    anamnese.funcionalidadeEIncapacidade.fatoresQueMelhoramADor
                      .descricao
                  }
                />
              )}
              {anamnese.funcionalidadeEIncapacidade
                .informacoesComplementares && (
                <ReportItem
                  title="Informações Complementares:"
                  description={
                    anamnese.funcionalidadeEIncapacidade
                      .informacoesComplementares
                  }
                />
              )}
              {anamnese.funcionalidadeEIncapacidade.limitacoesEmAtividade && (
                <ReportItem
                  title="Limitações em atividade:"
                  description={
                    anamnese.funcionalidadeEIncapacidade.limitacoesEmAtividade
                  }
                />
              )}
              {anamnese.funcionalidadeEIncapacidade
                .restricoesDeParticipacao && (
                <ReportItem
                  title="Restrições de Participação"
                  description={
                    anamnese.funcionalidadeEIncapacidade
                      .restricoesDeParticipacao
                  }
                />
              )}
            </div>
          </>
        )}
        {anamnese.fatoresContextuais?.observacoes && (
          <>
            <Spacer size={4} />
            <h2 style={{ color: color.strongBlue }}>Fatores Contextuais</h2>
            <p>{anamnese.fatoresContextuais.observacoes}</p>
          </>
        )}
        <h2 style={{ color: color.strongBlue }}>
          Histórico de Saúde do Paciente
        </h2>
        {anamnese.fatoresContextuais?.historicoDeSaudeDoPaciente && (
          <p>{anamnese.fatoresContextuais.historicoDeSaudeDoPaciente}</p>
        )}
        <p>
          <b>Atividades Físicas:</b>
          {!atividadesFisicas?.length && "Nenhuma"}
        </p>
        {atividadesFisicas.map((item) => {
          return (
            <p>
              {item.atividadePraticada} - {item.nivel}{" "}
              {item.frequencia ? `(${item.frequencia}x por semana)` : ""}
            </p>
          );
        })}
        {anamnese.fatoresContextuais.medicamentosEmUso && (
          <ReportItem
            title="Medicamentos em uso"
            description={anamnese.fatoresContextuais.medicamentosEmUso}
          />
        )}
        {anamnese.fatoresContextuais.examesComplementares && (
          <ReportItem
            title="Exames complementares"
            description={anamnese.fatoresContextuais.examesComplementares}
          />
        )}
        {anamnese.fatoresContextuais.objetivosDoCliente && (
          <ReportItem
            title="Objetivos do cliente"
            description={anamnese.fatoresContextuais.objetivosDoCliente}
          />
        )}
        {anamnese.fatoresContextuais.aplicacaoDeQuestionariosEspecificos && (
          <ReportItem
            title="Aplicação de questionários específicos"
            description={
              anamnese.fatoresContextuais.aplicacaoDeQuestionariosEspecificos
            }
          />
        )}
        {anamnese.fatoresContextuais.dadosVitais && (
          <>
            <h3 style={{ color: color.strongBlue }}>* Dados Vitais</h3>
            {anamnese.fatoresContextuais.dadosVitais.pressaoArterial && (
              <ReportItem
                title="Pressão arterial"
                description={
                  anamnese.fatoresContextuais.dadosVitais.pressaoArterial +
                  " mmHg"
                }
              />
            )}
            {!!anamnese.fatoresContextuais.dadosVitais.peso && (
              <ReportItem
                title="Peso"
                description={
                  toLocaleNumber(anamnese.fatoresContextuais.dadosVitais.peso) +
                  " kg"
                }
              />
            )}
            {!!anamnese.fatoresContextuais.dadosVitais.altura && (
              <ReportItem
                title="Altura"
                description={
                  toLocaleNumber(
                    anamnese.fatoresContextuais.dadosVitais.altura
                  ) + " m"
                }
              />
            )}
            {anamnese.fatoresContextuais.dadosVitais.outros && (
              <ReportItem
                title="Outros"
                description={anamnese.fatoresContextuais.dadosVitais.outros}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
