import React from "react";
import { AnalisePostural } from "../ReportsCRUD";
import ReportItem from "./ReportItem";
import { color } from "../../styles/components";

export default ({ analisePostural }: { analisePostural?: AnalisePostural }) => {
  if (!analisePostural) return null;

  return (
    <div>
      <h2 style={{ backgroundColor: color.softBlue, textAlign: "center" }}>
        Análise Postural
      </h2>
      <div style={{ paddingLeft: "4px" }}>
        {analisePostural.MMII && (
          <ReportItem title="MMII" description={analisePostural.MMII} />
        )}
        {analisePostural.tronco && (
          <ReportItem title="Tronco" description={analisePostural.tronco} />
        )}
        {analisePostural.MMSS && (
          <ReportItem title="MMSS" description={analisePostural.MMSS} />
        )}
      </div>
    </div>
  );
};
