import React from "react";

export default ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <>
      <p style={{ whiteSpace: "pre-line" }}>
        <b>{title}: </b>
        {description}
      </p>
    </>
  );
};
