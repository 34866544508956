import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loading from "../components/Loading";
import Spacer from "../components/Spacer";
import TabBar from "../components/TabBar";
import { db } from "../firebase/firebase-config";
import {
  buttonStyle,
  headerStyle,
  inputDivStyle,
  inputLabelDivStyle,
  inputStyle,
} from "../styles/components";
import { AnaliseDePadraoDeMovimento } from "./ReportsCRUD";

export default () => {
  const { costumerId, date } = useParams();
  const [padraoDeMovimento, setPadraoDeMovimento] =
    useState<AnaliseDePadraoDeMovimento>();

  useEffect(() => {
    const getAnamnese = async () => {
      if (!costumerId) return;
      const reportRef = doc(db, "report", costumerId);
      const docSnap = await getDoc(reportRef);
      const result = docSnap.data()?.[date!]
        .padraoDeMovimento as AnaliseDePadraoDeMovimento;
      const padraoDeMovimentoResult: AnaliseDePadraoDeMovimento = {
        ritmoEscapuloumeral: result?.ritmoEscapuloumeral || "",
        ritmoLombopelvico: result?.ritmoLombopelvico || "",
        inclinacaoLateralDeTronco: result?.inclinacaoLateralDeTronco || "",
        rotacaoDeTronco: result?.rotacaoDeTronco || "",
        extensaoDeTronco: result?.extensaoDeTronco || "",
        quadrupede: result?.quadrupede || "",
        pranchaLateral: result?.pranchaLateral || "",
        ponteComExtensaoUnilateralDeJoelho:
          result?.ponteComExtensaoUnilateralDeJoelho || "",
        agachamentoBipodal: result?.agachamentoBipodal || "",
        agachamentoUnipodal: result?.agachamentoUnipodal || "",
        caminhadaOuCorrida: result?.caminhadaOuCorrida || "",
        saltoBipodalOuUnipodal: result?.saltoBipodalOuUnipodal || "",
        outros: result?.outros || "",
      };

      setPadraoDeMovimento(padraoDeMovimentoResult);
    };
    getAnamnese();
  }, []);

  const navigate = useNavigate();

  const updateReport = async () => {
    const reportDoc = doc(db, "report", costumerId!);
    try {
      await setDoc(
        reportDoc,
        {
          [date!]: {
            padraoDeMovimento: padraoDeMovimento,
          },
        },
        { merge: true }
      );
      alert("Análise do Padrão de Movimento atualizada com sucesso!");
      navigate(`/editar-ficha/${costumerId}/${date}`);
    } catch (e) {
      alert("Erro ao atualizar os dados de Análise do Padrão de Movimento!");
      console.error(e);
    }
  };

  if (!padraoDeMovimento)
    return (
      <TabBar selected="Ficha de pacientes">
        <Loading />
      </TabBar>
    );

  return (
    <TabBar selected="Ficha de pacientes">
      <div style={headerStyle}>
        <button type="button" style={buttonStyle} onClick={() => navigate(-1)}>
          Voltar
        </button>
        <h1>Análise do Padrão de Movimento</h1>
        <button
          style={buttonStyle}
          type="button"
          onClick={() => updateReport()}
        >
          Salvar
        </button>
      </div>
      <Spacer size={32} />
      <div style={inputLabelDivStyle}>
        <label>Ritmo Escapuloumeral:</label>
        <textarea
          style={inputStyle}
          rows={3}
          value={padraoDeMovimento.ritmoEscapuloumeral}
          onChange={(k) =>
            setPadraoDeMovimento({
              ...padraoDeMovimento,
              ritmoEscapuloumeral: k.target.value,
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Ritmo Lombopélvico:</label>
        <textarea
          style={inputStyle}
          rows={3}
          value={padraoDeMovimento.ritmoLombopelvico}
          onChange={(k) =>
            setPadraoDeMovimento({
              ...padraoDeMovimento,
              ritmoLombopelvico: k.target.value,
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Inclinação Lateral de tronco:</label>
        <textarea
          style={inputStyle}
          rows={3}
          value={padraoDeMovimento.inclinacaoLateralDeTronco}
          onChange={(k) =>
            setPadraoDeMovimento({
              ...padraoDeMovimento,
              inclinacaoLateralDeTronco: k.target.value,
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Rotação de tronco:</label>
        <textarea
          style={inputStyle}
          rows={3}
          value={padraoDeMovimento.rotacaoDeTronco}
          onChange={(k) =>
            setPadraoDeMovimento({
              ...padraoDeMovimento,
              rotacaoDeTronco: k.target.value,
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Extensão de tronco:</label>
        <textarea
          style={inputStyle}
          rows={3}
          value={padraoDeMovimento.extensaoDeTronco}
          onChange={(k) =>
            setPadraoDeMovimento({
              ...padraoDeMovimento,
              extensaoDeTronco: k.target.value,
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Quadrúpede / Prancha Frontal:</label>
        <textarea
          style={inputStyle}
          rows={3}
          value={padraoDeMovimento.quadrupede}
          onChange={(k) =>
            setPadraoDeMovimento({
              ...padraoDeMovimento,
              quadrupede: k.target.value,
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Prancha Lateral:</label>
        <textarea
          style={inputStyle}
          rows={3}
          cols={80}
          value={padraoDeMovimento.pranchaLateral}
          onChange={(k) =>
            setPadraoDeMovimento({
              ...padraoDeMovimento,
              pranchaLateral: k.target.value,
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Ponte com Extensão Unilateral de Joelho:</label>
        <textarea
          style={inputStyle}
          rows={3}
          value={padraoDeMovimento.ponteComExtensaoUnilateralDeJoelho}
          onChange={(k) =>
            setPadraoDeMovimento({
              ...padraoDeMovimento,
              ponteComExtensaoUnilateralDeJoelho: k.target.value,
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Agachamento Bipodal / Sentar e Levantar:</label>
        <textarea
          style={inputStyle}
          rows={3}
          cols={80}
          value={padraoDeMovimento.agachamentoBipodal}
          onChange={(k) =>
            setPadraoDeMovimento({
              ...padraoDeMovimento,
              agachamentoBipodal: k.target.value,
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Agachamento Unipodal / Step Down Test</label>
        <textarea
          style={inputStyle}
          rows={3}
          value={padraoDeMovimento.agachamentoUnipodal}
          onChange={(k) =>
            setPadraoDeMovimento({
              ...padraoDeMovimento,
              agachamentoUnipodal: k.target.value,
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Caminhada / Corrida</label>
        <textarea
          style={inputStyle}
          rows={3}
          value={padraoDeMovimento.caminhadaOuCorrida}
          onChange={(k) =>
            setPadraoDeMovimento({
              ...padraoDeMovimento,
              caminhadaOuCorrida: k.target.value,
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Salto Bipodal / Salto Unipodal</label>
        <textarea
          style={inputStyle}
          rows={3}
          cols={80}
          value={padraoDeMovimento.saltoBipodalOuUnipodal}
          onChange={(k) =>
            setPadraoDeMovimento({
              ...padraoDeMovimento,
              saltoBipodalOuUnipodal: k.target.value,
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Outros:</label>
        <textarea
          style={inputStyle}
          rows={3}
          cols={80}
          value={padraoDeMovimento.outros}
          onChange={(k) =>
            setPadraoDeMovimento({
              ...padraoDeMovimento,
              outros: k.target.value,
            })
          }
        />
      </div>
    </TabBar>
  );
};
