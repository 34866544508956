import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loading from "../components/Loading";
import Spacer from "../components/Spacer";
import TabBar from "../components/TabBar";
import { db } from "../firebase/firebase-config";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  buttonStyle,
  flexStyle,
  headerStyle,
  inputDivStyle,
  inputLabelDivStyle,
  inputStyle,
} from "../styles/components";
import {
  Anamnese,
  AtividadeFisica,
  CaracteristicasDor,
  Duracao,
  Nivel,
  ObjetivosNaClinica,
  Ritmo,
} from "./ReportsCRUD";

export type RitmoDaDorLabelMap = {
  label: {
    [key in Ritmo]: string;
  };
};

export const RITMO_DA_DOR_LABEL: RitmoDaDorLabelMap = {
  label: {
    DegenerativaOuMecanica:
      "Dor em atividade que melhora com repouso (Degenerativa ou mecânica)",
    Inflamatoria: "Dor em atividade que piora com repouso (Inflamatória)",
    InflamatoriaCronica:
      "Dor matinal com rigidez que melhora com movimento (Inflamatoria crônica)",
    InflamatoriaAguda:
      "Dor em repouso que é pior no início da atividade (Inflamatória aguda)",
    AumentoDeCongestaoArticular:
      "Dor que aumenta ao longo do dia (Aumento de congestão articular)",
    OsseaOuDisturbiosOrganicos:
      "Dor que não é afetada por repouso ou atividade (óssea ou distúrbios orgânicos)",
  },
};

export default () => {
  const { costumerId, date } = useParams();
  const [anamnese, setAnamnese] = useState<Anamnese>();

  const [isInsertingExercise, setIsInsertingExercise] = useState(false);
  const defaultAtividadeFisica = {
    atividadePraticada: "",
    nivel: Nivel.Sedentário,
    frequencia: 0,
  };
  const [novaAtividadeFisica, setNovaAtividadeFisica] =
    useState<AtividadeFisica>(defaultAtividadeFisica);

  useEffect(() => {
    const getAnamnese = async () => {
      if (!costumerId) return;
      const reportRef = doc(db, "report", costumerId);
      const docSnap = await getDoc(reportRef);
      const result = docSnap.data()?.[date!].anamnese as Anamnese;
      const anamneseResult: Anamnese = {
        posturaDuranteODia: result?.posturaDuranteODia || "",
        objetivosNaClinica:
          result?.objetivosNaClinica || ObjetivosNaClinica.Reabilitacao,
        diagnosticoMedico: result?.diagnosticoMedico || "",
        queixaPrincipal: result?.queixaPrincipal || "",
        funcionalidadeEIncapacidade: {
          localizacaoDaDor:
            result?.funcionalidadeEIncapacidade?.localizacaoDaDor || "",
          inicioDaDor: result?.funcionalidadeEIncapacidade?.inicioDaDor || "",
          caracteristicaDaDor:
            result?.funcionalidadeEIncapacidade?.caracteristicaDaDor ||
            CaracteristicasDor.Continua,
          duracaoDaDor: result?.funcionalidadeEIncapacidade?.duracaoDaDor || "",
          ritmoDaDor: result?.funcionalidadeEIncapacidade?.ritmoDaDor || "",
          irradiacaoDaDor: {
            presente:
              !!result?.funcionalidadeEIncapacidade?.irradiacaoDaDor?.presente,
            regiao:
              result?.funcionalidadeEIncapacidade?.irradiacaoDaDor?.regiao ||
              "",
          },
          intensidade: result?.funcionalidadeEIncapacidade?.intensidade || 0,
          fatoresQuePioramADor: {
            presente:
              !!result?.funcionalidadeEIncapacidade?.fatoresQuePioramADor
                ?.presente,
            descricao:
              result?.funcionalidadeEIncapacidade?.fatoresQuePioramADor
                ?.descricao || "",
          },
          fatoresQueMelhoramADor: {
            presente:
              !!result?.funcionalidadeEIncapacidade?.fatoresQueMelhoramADor
                ?.presente,
            descricao:
              result?.funcionalidadeEIncapacidade?.fatoresQueMelhoramADor
                ?.descricao || "",
          },
          informacoesComplementares:
            result?.funcionalidadeEIncapacidade?.informacoesComplementares ||
            "",
          limitacoesEmAtividade:
            result?.funcionalidadeEIncapacidade?.limitacoesEmAtividade || "",
          restricoesDeParticipacao:
            result?.funcionalidadeEIncapacidade?.restricoesDeParticipacao || "",
        },
        fatoresContextuais: {
          observacoes: result?.fatoresContextuais?.observacoes || "",
          historicoDeSaudeDoPaciente:
            result?.fatoresContextuais?.historicoDeSaudeDoPaciente || "",
          historicoDeAtividadesFisicas:
            result?.fatoresContextuais?.historicoDeAtividadesFisicas || [],
          medicamentosEmUso:
            result?.fatoresContextuais?.medicamentosEmUso || "",
          examesComplementares:
            result?.fatoresContextuais?.examesComplementares || "",
          objetivosDoCliente:
            result?.fatoresContextuais?.objetivosDoCliente || "",
          aplicacaoDeQuestionariosEspecificos:
            result?.fatoresContextuais?.aplicacaoDeQuestionariosEspecificos ||
            "",
          dadosVitais: {
            pressaoArterial:
              result?.fatoresContextuais?.dadosVitais?.pressaoArterial || "",
            peso: result?.fatoresContextuais?.dadosVitais?.peso || 0,
            altura: result?.fatoresContextuais?.dadosVitais?.altura || 0,
            outros: result?.fatoresContextuais?.dadosVitais?.outros || "",
          },
        },
      };

      setAnamnese(anamneseResult);
    };
    getAnamnese();
  }, []);

  const navigate = useNavigate();

  const updateReport = async () => {
    const reportDoc = doc(db, "report", costumerId!);
    try {
      await setDoc(
        reportDoc,
        {
          [date!]: {
            anamnese: anamnese,
          },
        },
        { merge: true }
      );
      alert("Ficha Anamnese atualizada com sucesso!");
      navigate(`/editar-ficha/${costumerId}/${date}`);
    } catch (e) {
      alert("Erro ao atualizar os dados de anamnese!");
      console.error(e);
    }
  };

  if (!anamnese)
    return (
      <TabBar selected="Ficha de pacientes">
        <Loading />
      </TabBar>
    );

  return (
    <TabBar selected="Ficha de pacientes">
      <div style={headerStyle}>
        <button type="button" style={buttonStyle} onClick={() => navigate(-1)}>
          Voltar
        </button>
        <h1>Anamnese</h1>
        <button
          style={buttonStyle}
          type="button"
          onClick={() => updateReport()}
        >
          Salvar
        </button>
      </div>
      <Spacer size={32} />
      <div style={inputLabelDivStyle}>
        <label>Postura durante o dia:</label>
        <textarea
          style={inputStyle}
          rows={3}
          id="posturaDuranteODia"
          value={anamnese.posturaDuranteODia}
          onChange={(k) =>
            setAnamnese({ ...anamnese, posturaDuranteODia: k.target.value })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={flexStyle}>
        <div style={{ ...inputLabelDivStyle, flex: 1 }}>
          <label>Objetivos na clínica:</label>
          <select
            style={inputStyle}
            value={anamnese.objetivosNaClinica}
            onChange={(k) =>
              /**@ts-ignore */
              setAnamnese({ ...anamnese, objetivosNaClinica: k.target.value })
            }
          >
            <option value={ObjetivosNaClinica.Reabilitacao}>
              Reabilitação
            </option>
            <option value={ObjetivosNaClinica.Prevenção}>Prevenção</option>
            <option value={ObjetivosNaClinica.PromocaoDeSaude}>
              Promoção de saúde
            </option>
          </select>
        </div>
        <div style={{ ...inputLabelDivStyle, flex: 2 }}>
          <label>Diagnóstico médico:</label>
          <textarea
            style={inputStyle}
            id="diagnosticoMedico"
            rows={3}
            value={anamnese.diagnosticoMedico}
            onChange={(k) =>
              setAnamnese({ ...anamnese, diagnosticoMedico: k.target.value })
            }
          />
        </div>
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Queixa principal:</label>
        <textarea
          style={inputStyle}
          id="queixaPrincipal"
          placeholder="O que te fez procurar o serviço de fisioterapia ou pilates?"
          rows={3}
          value={anamnese.queixaPrincipal}
          onChange={(k) =>
            setAnamnese({ ...anamnese, queixaPrincipal: k.target.value })
          }
        />
      </div>
      <h2 style={{ color: "white" }}>História da Queixa Atual</h2>
      <Spacer size={4} />
      <h3 style={{ color: "white" }}>Alterações em estrutura e função</h3>
      <div style={inputLabelDivStyle}>
        <label>Localização da dor e mecanismo de lesão:</label>
        <textarea
          style={inputStyle}
          rows={3}
          placeholder="Qual estrutura? Qual região?"
          value={anamnese.funcionalidadeEIncapacidade.localizacaoDaDor}
          onChange={(k) =>
            setAnamnese({
              ...anamnese,
              funcionalidadeEIncapacidade: {
                ...anamnese.funcionalidadeEIncapacidade,
                localizacaoDaDor: k.target.value,
              },
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={flexStyle}>
        <div style={inputLabelDivStyle}>
          <label>Início da dor:</label>
          <select
            style={inputStyle}
            name="inicioDaDor"
            value={anamnese.funcionalidadeEIncapacidade.inicioDaDor}
            onChange={(k) =>
              setAnamnese({
                ...anamnese,
                funcionalidadeEIncapacidade: {
                  ...anamnese.funcionalidadeEIncapacidade,
                  /**@ts-ignore */
                  inicioDaDor: k.target.value,
                },
              })
            }
          >
            <option value={""}></option>
            <option value="súbito">Súbito</option>
            <option value="gradual">Gradual</option>
            <option value="lento">Lento</option>
          </select>
        </div>
        <div style={inputLabelDivStyle}>
          <label>Duração da dor:</label>
          <select
            style={inputStyle}
            name="duracaoDaDor"
            value={anamnese.funcionalidadeEIncapacidade.duracaoDaDor}
            onChange={(k) =>
              setAnamnese({
                ...anamnese,
                funcionalidadeEIncapacidade: {
                  ...anamnese.funcionalidadeEIncapacidade,
                  /**@ts-ignore */
                  duracaoDaDor: k.target.value,
                },
              })
            }
          >
            <option value={""}></option>
            <option value={Duracao.Aguda}>Aguda (7 a 10 dias)</option>
            <option value={Duracao.SubAguda}>
              Subaguda (10 dias a 7 semanas - 1 mês e meio)
            </option>
            <option value={Duracao.Cronica}>
              Crônica (7 semanas - 1 mês e meio)
            </option>
          </select>
        </div>
        <div style={{ ...inputLabelDivStyle, flex: 2 }}>
          <label>Ritmo da dor:</label>
          <select
            style={inputStyle}
            name="ritmoDaDor"
            value={anamnese.funcionalidadeEIncapacidade.ritmoDaDor}
            onChange={(k) =>
              setAnamnese({
                ...anamnese,
                funcionalidadeEIncapacidade: {
                  ...anamnese.funcionalidadeEIncapacidade,
                  /**@ts-ignore */
                  ritmoDaDor: k.target.value,
                },
              })
            }
          >
            <option value={""} />
            {Object.entries(RITMO_DA_DOR_LABEL.label).map((item) => (
              <option value={item[0]}>{item[1]}</option>
            ))}
          </select>
        </div>
      </div>
      <div style={inputDivStyle}>
        <input
          style={inputStyle}
          type="checkbox"
          id="irradiacaoDaDorPresente"
          checked={
            anamnese.funcionalidadeEIncapacidade.irradiacaoDaDor.presente
          }
          onChange={() =>
            setAnamnese({
              ...anamnese,
              funcionalidadeEIncapacidade: {
                ...anamnese.funcionalidadeEIncapacidade,
                irradiacaoDaDor: {
                  ...anamnese.funcionalidadeEIncapacidade.irradiacaoDaDor,
                  presente:
                    !anamnese.funcionalidadeEIncapacidade.irradiacaoDaDor
                      .presente,
                },
              },
            })
          }
        />
        <label>Irradiação da dor</label>
      </div>
      {anamnese.funcionalidadeEIncapacidade.irradiacaoDaDor.presente && (
        <div style={inputDivStyle}>
          <label>Qual região?</label>
          <br />
          <textarea
            style={inputStyle}
            id="regiao"
            rows={2}
            cols={60}
            value={
              anamnese?.funcionalidadeEIncapacidade?.irradiacaoDaDor?.regiao
            }
            onChange={(k) =>
              setAnamnese({
                ...anamnese,
                funcionalidadeEIncapacidade: {
                  ...anamnese.funcionalidadeEIncapacidade,
                  irradiacaoDaDor: {
                    ...anamnese.funcionalidadeEIncapacidade.irradiacaoDaDor,
                    regiao: k.target.value,
                  },
                },
              })
            }
          />
        </div>
      )}
      <div style={inputDivStyle}>
        <label style={{ marginRight: 4 }}>Intensidade: EVA</label>
        <input
          style={inputStyle}
          id="intensidade"
          min={0}
          max={10}
          type="number"
          value={anamnese.funcionalidadeEIncapacidade.intensidade}
          onChange={(k) =>
            setAnamnese({
              ...anamnese,
              funcionalidadeEIncapacidade: {
                ...anamnese.funcionalidadeEIncapacidade,
                /**@ts-ignore */
                intensidade: k.target.value,
              },
            })
          }
        />
      </div>

      <h3 style={{ color: "white" }}>
        Fatores, movimentos ou posições que alteram a queixa
      </h3>
      <div style={inputDivStyle}>
        <input
          style={inputStyle}
          type="checkbox"
          checked={
            anamnese.funcionalidadeEIncapacidade.fatoresQuePioramADor?.presente
          }
          onChange={() =>
            setAnamnese({
              ...anamnese,
              funcionalidadeEIncapacidade: {
                ...anamnese.funcionalidadeEIncapacidade,
                fatoresQuePioramADor: {
                  ...anamnese.funcionalidadeEIncapacidade.fatoresQuePioramADor,
                  presente:
                    !anamnese.funcionalidadeEIncapacidade.fatoresQuePioramADor
                      .presente,
                },
              },
            })
          }
        />
        <label>Pioram a queixa</label>
      </div>
      {!!anamnese.funcionalidadeEIncapacidade.fatoresQuePioramADor.presente && (
        <div style={inputLabelDivStyle}>
          <textarea
            style={inputStyle}
            rows={4}
            cols={60}
            value={
              anamnese?.funcionalidadeEIncapacidade?.fatoresQuePioramADor
                ?.descricao
            }
            placeholder="Postura, Esporte, Tensão emocional, Trauma, Repouso prolongado, Posição em pé, Posição sentada, Deambulação, Momento do dia"
            onChange={(k) =>
              setAnamnese({
                ...anamnese,
                funcionalidadeEIncapacidade: {
                  ...anamnese.funcionalidadeEIncapacidade,
                  fatoresQuePioramADor: {
                    ...anamnese.funcionalidadeEIncapacidade
                      .fatoresQuePioramADor,
                    descricao: k.target.value,
                  },
                },
              })
            }
          />
        </div>
      )}

      <div style={inputDivStyle}>
        <input
          style={inputStyle}
          type="checkbox"
          checked={
            anamnese.funcionalidadeEIncapacidade.fatoresQueMelhoramADor
              ?.presente
          }
          onChange={() =>
            setAnamnese({
              ...anamnese,
              funcionalidadeEIncapacidade: {
                ...anamnese.funcionalidadeEIncapacidade,
                fatoresQueMelhoramADor: {
                  ...anamnese.funcionalidadeEIncapacidade
                    .fatoresQueMelhoramADor,
                  presente:
                    !anamnese.funcionalidadeEIncapacidade.fatoresQueMelhoramADor
                      .presente,
                },
              },
            })
          }
        />
        <label>Melhoram a queixa</label>
      </div>
      {!!anamnese.funcionalidadeEIncapacidade.fatoresQueMelhoramADor
        .presente && (
        <div style={inputLabelDivStyle}>
          <textarea
            style={inputStyle}
            rows={4}
            value={
              anamnese?.funcionalidadeEIncapacidade?.fatoresQueMelhoramADor
                ?.descricao
            }
            placeholder="Repouso, Mudança de decúbito, Calor/Gelo, Medicação, Alongamento"
            onChange={(k) =>
              setAnamnese({
                ...anamnese,
                funcionalidadeEIncapacidade: {
                  ...anamnese.funcionalidadeEIncapacidade,
                  fatoresQueMelhoramADor: {
                    ...anamnese.funcionalidadeEIncapacidade
                      .fatoresQueMelhoramADor,
                    descricao: k.target.value,
                  },
                },
              })
            }
          />
        </div>
      )}

      <Spacer size={32} />
      <div style={inputLabelDivStyle}>
        <label>Informações complementares:</label>
        <textarea
          style={inputStyle}
          rows={3}
          value={
            anamnese?.funcionalidadeEIncapacidade?.informacoesComplementares
          }
          onChange={(k) =>
            setAnamnese({
              ...anamnese,
              funcionalidadeEIncapacidade: {
                ...anamnese.funcionalidadeEIncapacidade,
                informacoesComplementares: k.target.value,
              },
            })
          }
        />
      </div>
      <div style={inputLabelDivStyle}>
        <h3 style={{ color: "white" }}>Limitações em atividade:</h3>
        <textarea
          style={inputStyle}
          rows={4}
          placeholder={
            "Descrição das dificuldades apresentadas pelo paciente durante a execução de tarefas e/ou atividades de sua rotina de vida, em diversos contextos (isto é, pessoal, profissional e comportamental)"
          }
          value={anamnese.funcionalidadeEIncapacidade.limitacoesEmAtividade}
          onChange={(k) =>
            setAnamnese({
              ...anamnese,
              funcionalidadeEIncapacidade: {
                ...anamnese.funcionalidadeEIncapacidade,
                limitacoesEmAtividade: k.target.value,
              },
            })
          }
        />
      </div>
      <div style={inputLabelDivStyle}>
        <h3 style={{ color: "white" }}>Restrições de Participação:</h3>
        <textarea
          style={inputStyle}
          rows={4}
          cols={60}
          placeholder={
            "Descrição dos problemas enfrentados pelo paciente relativa à sua funcionalidade social em situações da vida real (cultural, comportamental e social)."
          }
          value={anamnese.funcionalidadeEIncapacidade.restricoesDeParticipacao}
          onChange={(k) =>
            setAnamnese({
              ...anamnese,
              funcionalidadeEIncapacidade: {
                ...anamnese.funcionalidadeEIncapacidade,
                restricoesDeParticipacao: k.target.value,
              },
            })
          }
        />
      </div>

      <h2 style={{ color: "white" }}>Fatores Contextuais</h2>
      <div style={inputLabelDivStyle}>
        <textarea
          style={inputStyle}
          rows={4}
          cols={60}
          placeholder={
            "Descrição dos fatores pessoais (internos) e ambientais (externos) que podem influenciar positivamente (auxiliar/ facilitar) ou negativamente (dificultar/barreiras) o paciente ao reestabelecimento de sua funcionalidade."
          }
          value={anamnese.fatoresContextuais.observacoes}
          onChange={(k) =>
            setAnamnese({
              ...anamnese,
              fatoresContextuais: {
                ...anamnese.fatoresContextuais,
                observacoes: k.target.value,
              },
            })
          }
        />
      </div>
      <div style={inputLabelDivStyle}>
        <h2 style={{ color: "white" }}>Histórico de saúde do paciente</h2>
        <textarea
          style={inputStyle}
          rows={4}
          cols={60}
          placeholder="Descrição de patologias associadas ou antigas; Histórico de saúde familiar;
            Cirurgias relevantes; histórico de quedas, entorses, luxações ou fraturas."
          value={anamnese.fatoresContextuais.historicoDeSaudeDoPaciente}
          onChange={(k) =>
            setAnamnese({
              ...anamnese,
              fatoresContextuais: {
                ...anamnese.fatoresContextuais,
                historicoDeSaudeDoPaciente: k.target.value,
              },
            })
          }
        />
      </div>
      <h3 style={{ color: "white" }}>Atividades Físicas</h3>
      {anamnese.fatoresContextuais.historicoDeAtividadesFisicas.map(
        (item, index) => (
          <div key={index}>
            <label>
              {item.atividadePraticada} - {item.nivel}{" "}
              {item.frequencia ? `(${item.frequencia}x por semana)` : ""}
            </label>
            <FontAwesomeIcon
              icon={faXmark}
              color="red"
              style={{ marginLeft: 10 }}
              onClick={() =>
                setAnamnese({
                  ...anamnese,
                  fatoresContextuais: {
                    ...anamnese.fatoresContextuais,
                    historicoDeAtividadesFisicas:
                      anamnese.fatoresContextuais.historicoDeAtividadesFisicas.filter(
                        (_, itemIndex) => itemIndex !== index
                      ),
                  },
                })
              }
            />
            <Spacer size={8} />
          </div>
        )
      )}
      <input
        type="button"
        value={"Adicionar nova"}
        style={{ ...buttonStyle, height: "50px", marginTop: "16px" }}
        onClick={() => setIsInsertingExercise(true)}
      />
      <Spacer size={16} />
      {isInsertingExercise && (
        <div style={flexStyle}>
          <div style={inputLabelDivStyle}>
            <label>Nome da atividade</label>
            <input
              style={inputStyle}
              value={novaAtividadeFisica.atividadePraticada}
              onChange={(k) =>
                setNovaAtividadeFisica({
                  ...novaAtividadeFisica,
                  atividadePraticada: k.target.value,
                })
              }
            />
          </div>
          <div style={inputLabelDivStyle}>
            <label>Nível</label>
            <select
              style={inputStyle}
              value={novaAtividadeFisica.nivel}
              onChange={(k) =>
                setNovaAtividadeFisica({
                  ...novaAtividadeFisica,
                  /**@ts-ignore */
                  nivel: k.target.value,
                })
              }
            >
              <option value="Sedentário">Sedentário</option>
              <option value="Ativo">Ativo</option>
              <option value="Atleta Amador">Atleta Amador</option>
              <option value="Atleta Profissional">Atleta Profissional</option>
            </select>
          </div>
          <div style={inputLabelDivStyle}>
            <label>Frequência Semanal</label>
            <input
              type="number"
              min={0}
              max={7}
              style={inputStyle}
              value={novaAtividadeFisica.frequencia}
              onChange={(k) =>
                setNovaAtividadeFisica({
                  ...novaAtividadeFisica,
                  frequencia: Number(k.target.value),
                })
              }
            />
          </div>
          <input
            type="button"
            value={"Ok"}
            style={{ ...buttonStyle, height: "50px", marginTop: "16px" }}
            onClick={() => {
              anamnese.fatoresContextuais.historicoDeAtividadesFisicas.push(
                novaAtividadeFisica
              );
              setIsInsertingExercise(false);
              setNovaAtividadeFisica(defaultAtividadeFisica);
            }}
          />
        </div>
      )}
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Medicamentos em uso:</label>
        <textarea
          style={inputStyle}
          value={anamnese.fatoresContextuais.medicamentosEmUso}
          rows={3}
          onChange={(k) =>
            setAnamnese({
              ...anamnese,
              fatoresContextuais: {
                ...anamnese.fatoresContextuais,
                medicamentosEmUso: k.target.value,
              },
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Exames complementares:</label>
        <textarea
          style={inputStyle}
          value={anamnese.fatoresContextuais.examesComplementares}
          rows={3}
          onChange={(k) =>
            setAnamnese({
              ...anamnese,
              fatoresContextuais: {
                ...anamnese.fatoresContextuais,
                examesComplementares: k.target.value,
              },
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Objetivos do cliente:</label>
        <textarea
          style={inputStyle}
          value={anamnese.fatoresContextuais.objetivosDoCliente}
          rows={3}
          onChange={(k) =>
            setAnamnese({
              ...anamnese,
              fatoresContextuais: {
                ...anamnese.fatoresContextuais,
                objetivosDoCliente: k.target.value,
              },
            })
          }
        />
      </div>
      <Spacer size={16} />
      <div style={inputLabelDivStyle}>
        <label>Aplicação de questionários específicos:</label>
        <textarea
          style={inputStyle}
          value={
            anamnese.fatoresContextuais.aplicacaoDeQuestionariosEspecificos
          }
          rows={3}
          onChange={(k) =>
            setAnamnese({
              ...anamnese,
              fatoresContextuais: {
                ...anamnese.fatoresContextuais,
                aplicacaoDeQuestionariosEspecificos: k.target.value,
              },
            })
          }
        />
      </div>
      <Spacer size={16} />
      <h3 style={{ color: "white" }}>Dados vitais</h3>
      <Spacer size={16} />
      <div style={flexStyle}>
        <div style={inputLabelDivStyle}>
          <label>Pressão arterial (mmHg)</label>
          <input
            style={inputStyle}
            value={anamnese.fatoresContextuais.dadosVitais.pressaoArterial}
            onChange={(k) =>
              setAnamnese({
                ...anamnese,
                fatoresContextuais: {
                  ...anamnese.fatoresContextuais,
                  dadosVitais: {
                    ...anamnese.fatoresContextuais.dadosVitais,
                    pressaoArterial: k.target.value,
                  },
                },
              })
            }
          />
        </div>
        <div style={inputLabelDivStyle}>
          <label>Peso (kg)</label>
          <input
            style={inputStyle}
            value={anamnese.fatoresContextuais.dadosVitais.peso}
            step="0.01"
            min={0}
            type="number"
            onChange={(k) =>
              setAnamnese({
                ...anamnese,
                fatoresContextuais: {
                  ...anamnese.fatoresContextuais,
                  dadosVitais: {
                    ...anamnese.fatoresContextuais.dadosVitais,
                    peso: Number(k.target.value),
                  },
                },
              })
            }
          />
        </div>
        <div style={inputLabelDivStyle}>
          <label>Altura (m)</label>
          <input
            style={inputStyle}
            value={anamnese.fatoresContextuais.dadosVitais.altura}
            step="0.01"
            min={0}
            type="number"
            onChange={(k) =>
              setAnamnese({
                ...anamnese,
                fatoresContextuais: {
                  ...anamnese.fatoresContextuais,
                  dadosVitais: {
                    ...anamnese.fatoresContextuais.dadosVitais,
                    altura: Number(k.target.value),
                  },
                },
              })
            }
          />
        </div>
      </div>
      <div style={inputLabelDivStyle}>
        <label>Outros:</label>
        <textarea
          style={inputStyle}
          rows={4}
          value={anamnese.fatoresContextuais.dadosVitais.outros}
          onChange={(k) =>
            setAnamnese({
              ...anamnese,
              fatoresContextuais: {
                ...anamnese.fatoresContextuais,
                dadosVitais: {
                  ...anamnese.fatoresContextuais.dadosVitais,
                  outros: k.target.value,
                },
              },
            })
          }
        />
      </div>
    </TabBar>
  );
};
