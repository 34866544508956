import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loading from "../components/Loading";
import TabBar from "../components/TabBar";
import { db } from "../firebase/firebase-config";
import {
  buttonStyle,
  flexStyle,
  headerStyle,
  inputDivStyle,
  inputLabelDivStyle,
  inputStyle,
} from "../styles/components";
import {
  Costumer,
  createCostumer,
  Dominancy,
  updateCostumer,
} from "./CostumerCRUD";
import Spacer from "../components/Spacer";

function setValue(
  value: string,
  setFunction: (v: string) => void,
  mask?: string
) {
  if (!mask) {
    setFunction(value);
    return;
  }
}

export default () => {
  const { id } = useParams();
  const [costumer, setCostumer] = useState<Costumer | undefined>();
  const [name, setName] = useState("");
  const [birthDate, setBirthDate] = useState("2000-01-01");
  const [occupation, setOccupation] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("Solteiro(a)");
  const [dominancy, setDominancy] = useState<Dominancy>(Dominancy.Destro);
  const [CPF, setCpf] = useState("");
  const [address, setAddress] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [emergencyPhone, setEmergencyPhone] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const getUser = async () => {
      if (!id) return;
      const costumerRef = doc(db, "costumers", id);
      const docSnap = await getDoc(costumerRef);
      setCostumer(docSnap.data() as Costumer);
    };
    getUser();
  }, []);

  useEffect(() => {
    if (costumer) {
      setName(costumer.name);
      setBirthDate(costumer.birthDate);
      setOccupation(costumer.occupation);
      setAddress(costumer.address);
      setDominancy(costumer.dominancy);
      setCpf(costumer.CPF);
      setCellPhone(costumer.cellPhone);
      setEmergencyPhone(costumer.emergencyPhone || "");
      setEmail(costumer.email || "");
    }
  }, [costumer]);

  const navigate = useNavigate();

  function onSubmit() {
    const savedCostumer: Costumer = {
      name,
      birthDate,
      occupation,
      maritalStatus,
      dominancy,
      CPF,
      address,
      cellPhone,
      emergencyPhone,
      email,
    };
    console.log(savedCostumer);
    try {
      id ? updateCostumer(id, savedCostumer) : createCostumer(savedCostumer);
      navigate("/");
    } catch (e) {
      alert("Erro ao registrar cliente! " + e);
    }
  }

  if (id && !costumer)
    return (
      <TabBar selected="Clientes">
        <Loading />
      </TabBar>
    );

  return (
    <TabBar selected="Clientes">
      <form onSubmit={onSubmit}>
        <div style={headerStyle}>
          <button
            type="button"
            style={buttonStyle}
            onClick={() => navigate(-1)}
          >
            Voltar
          </button>
          <h1>Cadastrar Cliente</h1>
          <button style={buttonStyle} type="submit">
            Salvar
          </button>
        </div>
        <Spacer size={32} />
        <div style={inputLabelDivStyle}>
          <label>Nome:</label>
          <input
            style={inputStyle}
            required
            type="text"
            id="name"
            value={name}
            onChange={(k) => setValue(k.target.value, setName)}
          />
        </div>
        <Spacer size={16} />
        <div style={flexStyle}>
          <div style={inputLabelDivStyle}>
            <label>Estado Civil:</label>
            <select
              style={inputStyle}
              name="maritalStatus"
              value={maritalStatus}
              onChange={(k) => setMaritalStatus(k.target.value)}
            >
              <option value="Solteiro(a)">Solteiro(a)</option>
              <option value="Casado(a)">Casado(a)</option>
              <option value="Divorciado(a)">Divorciado(a)</option>
              <option value="Viúvo(a)">Viúvo(a)</option>
            </select>
          </div>
          <div style={inputLabelDivStyle}>
            <label>Data de Nascimento:</label>
            <input
              style={inputStyle}
              type="date"
              value={birthDate}
              onChange={(k) => setValue(k.target.value, setBirthDate)}
              required
            />
          </div>
          <div style={inputLabelDivStyle}>
            <label>Profissão:</label>
            <input
              style={inputStyle}
              type="text"
              value={occupation}
              onChange={(k) => setValue(k.target.value, setOccupation)}
              required
            />
          </div>
        </div>
        <Spacer size={16} />
        <div style={flexStyle}>
          <div style={inputLabelDivStyle}>
            <label>Dominância de MMSS:</label>
            <select
              style={inputStyle}
              value={dominancy}
              /**@ts-ignore */
              onChange={(k) => setDominancy(k.target.value)}
            >
              <option value={Dominancy.Destro}>Destro(a)</option>
              <option value={Dominancy.Canhoto}>Canhoto(a)</option>
            </select>
          </div>
          <div style={{ ...inputLabelDivStyle, flex: 2 }}>
            <label>CPF:</label>
            <input
              style={inputStyle}
              type="text"
              value={CPF}
              onChange={(k) => setValue(k.target.value, setCpf)}
              required
            />
          </div>
        </div>
        <Spacer size={16} />
        <div style={inputLabelDivStyle}>
          <label>Endereço:</label>
          <input
            style={inputStyle}
            type="text"
            value={address}
            onChange={(k) => setValue(k.target.value, setAddress)}
            required
          />
        </div>
        <Spacer size={16} />
        <div style={flexStyle}>
          <div style={inputLabelDivStyle}>
            <label>Telefone Celular:</label>
            <input
              style={inputStyle}
              type="text"
              value={cellPhone}
              onChange={(k) => setValue(k.target.value, setCellPhone)}
              required
            />
          </div>
          <div style={inputLabelDivStyle}>
            <label>Telefone de Emergência:</label>
            <input
              style={inputStyle}
              type="text"
              value={emergencyPhone}
              onChange={(k) => setValue(k.target.value, setEmergencyPhone)}
            />
          </div>
        </div>
        <Spacer size={16} />
        <div style={inputLabelDivStyle}>
          <label>E-mail:</label>
          <input
            style={inputStyle}
            type="email"
            value={email}
            onChange={(k) => setValue(k.target.value, setEmail)}
          />
        </div>
      </form>
    </TabBar>
  );
};
