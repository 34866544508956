export const color = {
  softBlue: "#a3e5d8",
  strongBlue: "#3C9BA5",
  warning: "#E08A1F",
  error: "#D40F08",
  success: "#0DF56D",
};

export const buttonStyle = {
  backgroundColor: color.strongBlue,
  borderRadius: 10,
  border: "none",
  paddingLeft: 30,
  paddingRight: 30,
};

export const inputDivStyle = { marginTop: 16, marginBottom: 16 };

export const inputLabelDivStyle = {
  display: "flex",
  flex: 1,
  flexDirection: "column" as "column",
};

export const inputStyle = {
  backgroundColor: color.softBlue,
  fontSize: 18,
  marginTop: 10,
  marginBottom: 10,
  border: 0,
  padding: 10,
};

export const selectStyle = {
  backgroundColor: color.softBlue,
  fontSize: 15,
  marginTop: 10,
  marginBottom: 10,
  paddingTop: 10,
  paddingBottom: 10,
  border: 0,
};

export const flexStyle = {
  display: "flex",
  flexWrap: "wrap" as "wrap",
  gap: 20,
};

export const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  paddingTop: 16,
  flexWrap: "wrap" as "wrap",
};

export const headerTitleStyle = {
  textAlign: "center" as "center",
  flex: 1,
};
