import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loading from "../components/Loading";
import TabBar from "../components/TabBar";
import { db } from "../firebase/firebase-config";
import {
  buttonStyle,
  headerStyle,
  inputDivStyle,
  inputLabelDivStyle,
  inputStyle,
} from "../styles/components";
import { Diagnostico } from "./ReportsCRUD";
import { Link } from "react-router-dom";
import Spacer from "../components/Spacer";

export default () => {
  const { costumerId, date } = useParams();
  const [diagnostico, setDiagnostico] = useState<Diagnostico>();

  useEffect(() => {
    const getDiagnostico = async () => {
      if (!costumerId) return;
      const reportRef = doc(db, "report", costumerId);
      const docSnap = await getDoc(reportRef);
      const result = docSnap.data()?.[date!].diagnostico as Diagnostico;
      setDiagnostico({
        diagnosticoFisioterapeutico: result?.diagnosticoFisioterapeutico || "",
        objetivosDoTratamentoFisioterapeutico:
          result?.objetivosDoTratamentoFisioterapeutico || "",
      });
    };
    getDiagnostico();
  }, []);

  const navigate = useNavigate();

  const updateReport = async () => {
    const reportDoc = doc(db, "report", costumerId!);
    try {
      await setDoc(
        reportDoc,
        {
          [date!]: {
            diagnostico: diagnostico,
          },
        },
        { merge: true }
      );
      alert("Diagnóstico atualizado com sucesso!");
      navigate(`/editar-ficha/${costumerId}/${date}`);
    } catch (e) {
      alert("Erro ao atualizar os dados de diagnóstico!");
      console.error(e);
    }
  };

  if (!diagnostico)
    return (
      <TabBar selected="Ficha de pacientes">
        <Loading />
      </TabBar>
    );

  return (
    <TabBar selected="Ficha de pacientes">
      <div style={headerStyle}>
        <button type="button" style={buttonStyle} onClick={() => navigate(-1)}>
          Voltar
        </button>
        <h1 style={{ textAlign: "center" }}>
          DIAGNÓSTICO FISIOTERAPÊUTICO E OBJETIVOS
        </h1>
        <button
          style={buttonStyle}
          type="button"
          onClick={() => updateReport()}
        >
          Salvar
        </button>
      </div>

      <Spacer size={32} />
      <div style={inputLabelDivStyle}>
        <label>Diagnóstico fisioterapêutico:</label>
        <textarea
          style={inputStyle}
          rows={4}
          cols={72}
          value={diagnostico.diagnosticoFisioterapeutico}
          onChange={(k) =>
            setDiagnostico({
              ...diagnostico,
              diagnosticoFisioterapeutico: k.target.value,
            })
          }
        />
      </div>

      <div style={inputLabelDivStyle}>
        <label>Objetivos do tratamento fisioterapêutico:</label>
        <textarea
          style={inputStyle}
          rows={4}
          cols={72}
          value={diagnostico.objetivosDoTratamentoFisioterapeutico}
          onChange={(k) =>
            setDiagnostico({
              ...diagnostico,
              objetivosDoTratamentoFisioterapeutico: k.target.value,
            })
          }
        />
      </div>

      {diagnostico?.diagnosticoFisioterapeutico &&
        diagnostico?.objetivosDoTratamentoFisioterapeutico && (
          <Link
            to={`/relatorio-diagnostico/${costumerId}/${date}`}
            style={{ color: "white" }}
          >
            Imprimir diagnóstico
          </Link>
        )}
    </TabBar>
  );
};
