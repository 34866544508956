import { doc, getDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import TabBar from "../components/TabBar";
import { db } from "../firebase/firebase-config";
import { buttonStyle, color, headerStyle } from "../styles/components";
import { ReportInfo } from "./ReportsCRUD";
import {
  faPenToSquare,
  faFileLines,
  faXmark,
  faCheck,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateToString } from "../utils/date";
import Loading from "../components/Loading";
import { UserContext } from "../App";
import { Link } from "react-router-dom";
import Spacer from "../components/Spacer";
import dayjs from "dayjs";

const styles = {
  reportItem: {
    backgroundColor: color.softBlue,
    marginTop: "3%",
    marginBottom: "3%",
    padding: "2%",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  label: { color: "black", marginLeft: 5 },
};

export function getIcon(present: boolean) {
  if (present) return <FontAwesomeIcon icon={faCheck} color="#00b300" />;
  return (
    <FontAwesomeIcon icon={faXmark} color="red" style={{ marginRight: 5 }} />
  );
}

export default () => {
  const employeeId = useContext(UserContext)[0];

  const { id } = useParams();
  const [reports, setReports] = useState<ReportInfo | null>();

  const [costumerName, setCostumerName] = useState<string>();

  const navigate = useNavigate();

  useEffect(() => {
    const getUserReports = async () => {
      if (!id) return;
      const reportRef = doc(db, "report", id);
      const docSnap = await getDoc(reportRef);
      if (docSnap?.data() === undefined) {
        setReports(null);
      } else {
        setReports(docSnap.data());
      }
    };

    const getUser = async () => {
      if (!id) return;
      const costumerRef = doc(db, "costumers", id);
      const docSnap = await getDoc(costumerRef);
      setCostumerName(docSnap.data()?.name);
    };

    getUserReports();
    getUser();
  }, []);

  if (reports === undefined) {
    return (
      <TabBar selected="Ficha de Pacientes">
        <Loading />
      </TabBar>
    );
  }

  const sortedReports = reports
    ? Object.entries(reports).sort((a, b) => {
        const dateA = dayjs(a[0]);
        const dateB = dayjs(b[0]);
        return dateA.isAfter(dateB) ? -1 : 1;
      })
    : [];

  return (
    <TabBar selected="Ficha de pacientes">
      <div style={headerStyle}>
        <button style={buttonStyle} onClick={() => navigate(-1)}>
          Voltar
        </button>
        <h1>Paciente: {costumerName}</h1>
        <button
          style={buttonStyle}
          onClick={() => navigate(`/nova-ficha${id}`)}
        >
          Nova ficha
        </button>
      </div>
      {reports === null ? (
        <p style={{ color: "white" }}>
          Nenhuma ficha cadastrada para esse paciente.
        </p>
      ) : (
        sortedReports.map((item) => {
          const date = item[0];
          return (
            <div key={date} style={styles.reportItem}>
              <div style={{ flexDirection: "column" }}>
                <p>{dateToString(date)}</p>
                <div>
                  {getIcon(!!item[1].anamnese)}
                  <label style={styles.label}>Anamnese</label>
                </div>

                <div>
                  {getIcon(!!item[1].analisePostural)}
                  <label style={styles.label}>Análise Postural</label>
                </div>

                <div>
                  {getIcon(!!item[1].padraoDeMovimento)}
                  <label style={styles.label}>
                    Análise de Padrão de Movimento
                  </label>
                </div>

                <div>
                  {getIcon(!!item[1].testesEspecificos)}
                  <label style={styles.label}>
                    Testes Específicos: Mobilidade, Flexibilidade e Força
                  </label>
                </div>

                <div>
                  {getIcon(!!item[1].testesFuncionais)}
                  <label style={styles.label}>
                    Testes Funcionais do Pilates
                  </label>
                </div>

                <div>
                  {getIcon(!!item[1].diagnostico)}
                  <label style={styles.label}>
                    Diagnóstico Fisioterapêutico e Objetivos
                  </label>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                {item[1].employeeId === employeeId && (
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    size={"2x"}
                    onClick={() => navigate(`/editar-ficha/${id}/${date}`)}
                  />
                )}
                <Spacer size={8} row />
                <Link to={`/relatorio/${id}/${date}`}>
                  <FontAwesomeIcon icon={faFileAlt} size={"2x"} color="black" />
                </Link>
              </div>
            </div>
          );
        })
      )}
    </TabBar>
  );
};
