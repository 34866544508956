import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loading from "../components/Loading";
import Spacer from "../components/Spacer";
import TabBar from "../components/TabBar";
import { db } from "../firebase/firebase-config";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  buttonStyle,
  color,
  headerStyle,
  inputDivStyle,
  inputLabelDivStyle,
  inputStyle,
} from "../styles/components";
import { Teste, TestesEspecificos } from "./ReportsCRUD";
import Divider from "../components/Divider";

const MOBILITY_ITENS = [
  "Cervical: Flexão [80°]",
  "Cervical: Extensão [70°]",
  "Cervical: Flexão Lateral [20° - 45°]",
  "Cervical: Rotação [70° - 90°]",
  "Torácica: Flexão [20° - 45°]",
  "Torácica: Extensão [25° - 45°]",
  "Torácica: Flexão Lateral [20° - 40°]",
  "Torácica: Rotação [35° - 50°]",
  "Lombar: Flexão  [40° - 60°]",
  "Lombar: Extensão [20° - 35°]",
  "Lombar: Flexão Lateral [15° - 20°]",
  "Lombar: Rotação [3° - 18°]",
  "Ombro: Flexão [160° - 180°]",
  "Ombro: Extensão [50° - 60°]",
  "Ombro: Abdução [170° - 180°]",
  "Ombro: Adução [50° - 75°]",
  "Ombro: Abdução Horizontal [130°]",
  "Ombro: Adução Horizontal [130°]",
  "Ombro: Rotação Lateral [80° - 90°]",
  "Ombro: Rotação Medial [60°]",
  "Ombro: Rotação Lateral c/ abdução 90° [90°]",
  "Ombro: Rotação Medial c/ abdução 90° [60°]",
  "Cotovelo: Flexão [140° - 150°]",
  "Cotovelo: Extensão [0 – 10°]",
  "Antebraço: Pronação [80°]",
  "Antebraço: Supinação [90°]",
  "Punho: Flexão [80° - 90°]",
  "Punho: Extensão [70° - 90°]",
  "Punho: Desvio Ulnar [30° - 45°]",
  "Punho: Desvio Radial [15°]",
  "Quadril: Flexão [110° - 120°]",
  "Quadril: Extensão [10° -15°]",
  "Quadril: Abdução [30° - 50°]",
  "Quadril: Adução [30°]",
  "Quadril: Rotação Lateral [40° - 60°]",
  "Quadril: Rotação Medial [30° - 40°]",
  "Joelho: Flexão [0 - 135°]",
  "Joelho: Extensão [0 – 15°]",
  "Tíbia: Rotação Medial [20° - 30°]",
  "Tíbia: Rotação Lateral [30° - 40°]",
  "Tornozelo: Dorsiflexão [20°]",
  "Tornozelo: Flexão Plantar [50°]",
  "Tornozelo: Eversão [10° - 20°]",
  "Tornozelo: Inversão [15° - 30°]",
];

const FLEXIBILITY_ITENS = [
  "Escalenos*",
  "Esternocleidomastóideo*",
  "Eretores da espinha – cervical*",
  "Trapézio fibras superiores*",
  "Levantador da escápula*",
  "Peitoral menor*",
  "Peitoral maior*",
  "Redondo Maior",
  "Deltoide fibras posteriores",
  "Subescapular*",
  "Grande dorsal*",
  "Flexores de ombro*",
  "Tríceps braquiais",
  "Flexores de cotovelo*",
  "Flexores de punho*",
  "Extensores de punho",
  "Eretores da espinha – torácico*",
  "Eretores da espinha - lombar*",
  "Quadrado lombar*",
  "Flexores de tronco",
  "Ileopsoas* ",
  "Reto femoral* ",
  "Isquiossurais*",
  "Tensor da fáscia lata* - Teste de Ober",
  "Adutores de quadril*",
  "Piriforme*",
  "Rotadores mediais de quadril",
  "Rotadores laterais de quadril",
  "Tríceps surais – Sóleo*",
  "Tríceps surais – Gastrocnêmio*",
  "Tibial posterior*",
  "Fáscia Plantar",
  "Dorsiflexores",
  "Extensores dos artelhos",
  "Outros",
];

const STRENGHT_ITEMS = [
  "Flexores profundos cervicais* ",
  "Flexores superficiais da cervical ",
  "Extensores da cervical ",
  "Flexores laterais da cervical  ",
  "Rotadores da cervical ",
  "Trapézio fibras superiores",
  "Trapézio fibras inferiores*",
  "Trapézio fibras médias/ Rombóides* ",
  "Serrátil anterior* ",
  "Supraespinhoso",
  "Subescapular",
  "Flexores de ombro ",
  "Extensores de ombro*",
  "Abdutores de ombro ",
  "Adutores de ombro ",
  "Rotadores laterais de ombro ",
  "Rotadores mediais de ombro ",
  "Flexores de cotovelo ",
  "Extensores de cotovelo*",
  "Pronadores ",
  "Supinadores ",
  "Flexores de punho ",
  "Extensores de punho* ",
  "Desvio radial ",
  "Flexores de polegar ",
  "Extensores de polegar ",
  "Abdutores de polegar ",
  "Adutores de polegar ",
  "Opositores do polegar ",
  "Abdutores dos dedos ",
  "Adutores dos dedos ",
  "Força de preensão cilíndrica ",
  "Reto abdominal* ",
  "Oblíquos do abdômen ",
  "Extensores de tronco - Torácicos e Lombares ",
  "Flexores de quadril ",
  "Extensores de quadril/ Glúteo máximo* ",
  "Abdutores de quadril/ Glúteo médio* ",
  "Adutores de quadril ",
  "Rotadores laterais de quadril ",
  "Extensores de joelho* ",
  "Flexores de joelho ",
  "Dorsiflexores* ",
  "Flexores plantares – Gastrocnêmio e Sóleo ",
  "Inversores",
  "Eversores/ Fibulares* ",
  "Outros",
];

const COMPLEMENTARY_TESTS_ITEMS = [
  "Alinhamento perna-antepé (Referência normalidade 10° - 13,9°)",
  "ADM dorsiflexão em pé (Referência normalidade > 45°)",
  "Antiversão de colo do fêmur (Referência normalidade 8° - 15°)",
  "Rigidez passiva de rotadores laterais de quadril (Referência normalidade 30° - 40°)",
  "Medida de comprimento de membros inferiores",
  "Impacto fêmoro-acetabular",
  "Mobilidade de Patela",
  "Teste de Jobe – Lata Vazia (Sintomas de Impacto)",
  "Teste de Neer (Sintomas de Impacto)",
  "Teste de Hawkins (Sintomas de Impacto)",
  "Teste Lata Cheia (Lesão de Tendões do Manguito)",
  "Teste de Assistência Escapular – TAE (Estabilidade Escapular)",
  "Teste de Reposicionamento Escapular – TER (Estabilidade Escapular)",
  "Teste de Apreensão, Realocação e Liberação (Instabilidade Glenoumeral anterior ou posterior)",
  "Teste de Lassidão (Instabilidade Glenoumeral posterior) ",
  "Teste de O’Brien (Lesão do Bíceps ou Labral – SLAP)",
  "Teste de Speed (Lesão do Bíceps)",
  "Teste de Carga do Bíceps II (Lesão do Bíceps)",
  "Teste de Roos – EAST (Desfiladeiro Torácico)",
  "ULTT1 – Nervo Mediano",
  "ULTT2 – Nervo Radial",
  "ULTT3 – Nervo Ulnar",
  "Teste de Laségue",
  "Teste de SLUMP",
  "Dominância: Rombóides x Cápsula posterior (deltoide posterior)",
  "Dominância: Rombóides x Redondo maior ",
  "Dominância: Rombóides x Rotadores laterais de ombro",
  "Dominância: Deltoide posterior x Rotadores laterais de ombro ",
  "Dominância: Deltoide posterior x Redondo maior",
  "Dominância: Extensores de quadril x Extensores lombares",
  "Dominância: Glúteo máximo x Isquiossurais",
  "Dominância: Tensor da Fáscia Lata x Abdutores de quadril",
  "Dominância: Abdutores de quadril x Quadrado lombar",
  "Dominância: Flexores de quadril x Abdominais",
  "Dominância: Reto femoral x Abdominais",
  "Dominância: Extensores lombares x Isquiossurais (Sentado)",
  "Teste de Transmissão de Força Miofascial entre glúteo máximo e Grande dorsal",
  "Teste de Estresse Sacroilíaca – Distração",
  "Teste de Estresse Sacroilíaca – Cisalhamento posterior",
  "Teste de Estresse Sacroilíaca – Compressão",
  "Teste de Estresse Sacroilíaca – Cisalhamento anterior",
  "Testes de Equilíbrio",
  "Outros Testes",
];

export default () => {
  const { costumerId, date } = useParams();
  const [testesEspecificos, setTestesEspecificos] =
    useState<TestesEspecificos>();

  const [isInsertingMobility, setIsInsertingMobility] = useState(false);
  const defaultMobility = {
    item: MOBILITY_ITENS[0],
    resultado: "",
  };
  const [newMobility, setNewMobility] = useState<Teste>(defaultMobility);

  const [isInsertingFlexibility, setIsInsertingFlexibility] = useState(false);
  const defaultFlexibility = {
    item: FLEXIBILITY_ITENS[0],
    resultado: "",
  };
  const [newFlexibility, setNewFlexibility] =
    useState<Teste>(defaultFlexibility);

  const [isInsertingStrenght, setIsInsertingStrenght] = useState(false);
  const defaultStrenght = {
    item: STRENGHT_ITEMS[0],
    resultado: "",
  };
  const [newStrenght, setNewStrenght] = useState<Teste>(defaultStrenght);

  const [isInsertingComplementaryTest, setIsInsertingComplementaryTest] =
    useState(false);
  const defaultComplementaryTest = {
    item: COMPLEMENTARY_TESTS_ITEMS[0],
    resultado: "",
  };
  const [newComplementaryTest, setNewComplementaryTest] = useState<Teste>(
    defaultComplementaryTest
  );

  useEffect(() => {
    const getTests = async () => {
      if (!costumerId) return;
      const reportRef = doc(db, "report", costumerId);
      const docSnap = await getDoc(reportRef);
      const result = docSnap.data()?.[date!]
        .testesEspecificos as TestesEspecificos;
      const testsResult: TestesEspecificos = {
        mobilidade: result?.mobilidade || [],
        flexibilidade: result?.flexibilidade || [],
        forcaOuFuncaoMuscular: result?.forcaOuFuncaoMuscular || [],
        testesComplementares: result?.testesComplementares || [],
        outros: result?.outros || "",
      };

      setTestesEspecificos(testsResult);
    };
    getTests();
  }, []);

  const navigate = useNavigate();

  const updateReport = async () => {
    const reportDoc = doc(db, "report", costumerId!);
    try {
      await setDoc(
        reportDoc,
        { [date!]: { testesEspecificos } },
        { merge: true }
      );
      alert("Testes específicos atualizados com sucesso!");
      navigate(`/editar-ficha/${costumerId}/${date}`);
    } catch (e) {
      alert("Erro ao atualizar os testes específicos!");
      console.error(e);
    }
  };

  if (!testesEspecificos)
    return (
      <TabBar selected="Ficha de pacientes">
        <Loading />
      </TabBar>
    );

  return (
    <TabBar selected="Ficha de pacientes">
      <div style={headerStyle}>
        <button type="button" style={buttonStyle} onClick={() => navigate(-1)}>
          Voltar
        </button>
        <h1>Testes Específicos: Mobilidade, Flexibilidade e Força</h1>
        <button
          style={buttonStyle}
          type="button"
          onClick={() => updateReport()}
        >
          Salvar
        </button>
      </div>
      <Spacer size={32} />
      <h3 style={{ color: "white" }}>
        MOBILIDADE (ATIVA - PASSIVA - END FEEL - PADRÃO CAPSULAR)
      </h3>
      {testesEspecificos.mobilidade.map((teste, index) => (
        <div key={index}>
          <label>
            {teste.item} - {teste.resultado}
          </label>
          <FontAwesomeIcon
            icon={faXmark}
            color="red"
            style={{ marginLeft: 10 }}
            onClick={() =>
              setTestesEspecificos({
                ...testesEspecificos,
                mobilidade: testesEspecificos.mobilidade.filter(
                  (_, itemIndex) => itemIndex !== index
                ),
              })
            }
          />
          <Spacer size={8} />
        </div>
      ))}
      <input
        type="button"
        value={"Adicionar nova"}
        style={{ ...buttonStyle, height: "50px", marginTop: "16px" }}
        onClick={() => setIsInsertingMobility(true)}
      />
      <Spacer size={16} />
      {isInsertingMobility && (
        <div style={inputLabelDivStyle}>
          <select
            style={{ ...inputStyle, maxWidth: "532px" }}
            value={newMobility.item}
            onChange={(k) =>
              setNewMobility({
                ...newMobility,
                item: k.target.value,
              })
            }
          >
            {MOBILITY_ITENS.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
          <div style={inputLabelDivStyle}>
            <label>Resultado:</label>
            <textarea
              style={{ ...inputStyle, maxWidth: "512px" }}
              value={newMobility.resultado}
              rows={1}
              cols={45}
              onChange={(k) =>
                setNewMobility({ ...newMobility, resultado: k.target.value })
              }
            />
          </div>
          <Spacer size={16} />
          <input
            type="button"
            value={"Ok"}
            style={{ ...buttonStyle, height: "50px", maxWidth: "128px" }}
            onClick={() => {
              testesEspecificos.mobilidade.push(newMobility);
              setIsInsertingMobility(false);
              setNewMobility(defaultMobility);
            }}
          />
        </div>
      )}
      <Spacer size={4} />
      <Divider />

      <div style={inputDivStyle}>
        <h3 style={{ color: "white" }}>FLEXIBILIDADE</h3>
        {testesEspecificos.flexibilidade.map((teste, index) => (
          <div key={index}>
            <label>
              {teste.item} - {teste.resultado}
            </label>
            <FontAwesomeIcon
              icon={faXmark}
              color="red"
              style={{ marginLeft: 10 }}
              onClick={() =>
                setTestesEspecificos({
                  ...testesEspecificos,
                  flexibilidade: testesEspecificos.flexibilidade.filter(
                    (_, itemIndex) => itemIndex !== index
                  ),
                })
              }
            />
            <Spacer size={8} />
          </div>
        ))}
      </div>
      <input
        type="button"
        value={"Adicionar nova"}
        style={{ ...buttonStyle, height: "50px", marginTop: "16px" }}
        onClick={() => setIsInsertingFlexibility(true)}
      />
      <Spacer size={16} />
      {isInsertingFlexibility && (
        <>
          <div style={inputLabelDivStyle}>
            <select
              style={{ ...inputStyle, maxWidth: "532px" }}
              value={newFlexibility.item}
              onChange={(k) =>
                setNewFlexibility({
                  ...newFlexibility,
                  item: k.target.value,
                })
              }
            >
              {FLEXIBILITY_ITENS.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div>
          <Spacer size={16} row />
          <div style={inputLabelDivStyle}>
            <label>Resultado:</label>
            <textarea
              style={{ ...inputStyle, maxWidth: "512px" }}
              value={newFlexibility.resultado}
              rows={1}
              onChange={(k) =>
                setNewFlexibility({
                  ...newFlexibility,
                  resultado: k.target.value,
                })
              }
            />
          </div>
          <Spacer size={16} />
          <input
            type="button"
            value={"Ok"}
            style={{ ...buttonStyle, height: "50px" }}
            onClick={() => {
              testesEspecificos.flexibilidade.push(newFlexibility);
              setIsInsertingFlexibility(false);
              setNewFlexibility(defaultFlexibility);
            }}
          />
        </>
      )}
      <Spacer size={4} />
      <Divider />

      <div style={inputDivStyle}>
        <h3
          title='TESTE ISOMÉTRICO RESISTIDO:
            "Não deixe que eu te mova"
            - Articulação em repouso;
            - Sustentar a contração por 5 seg
            TESTE ISOTÔNICO
            - Função Muscular
            - Quantidade ou Qualidade da ativação.'
          style={{ color: "white" }}
        >
          FORÇA/ FUNÇÃO MUSCULAR{" "}
        </h3>
        {testesEspecificos.forcaOuFuncaoMuscular.map((teste, index) => (
          <div key={index}>
            <label>
              {teste.item} - {teste.resultado}
            </label>
            <FontAwesomeIcon
              icon={faXmark}
              color="red"
              style={{ marginLeft: 10 }}
              onClick={() =>
                setTestesEspecificos({
                  ...testesEspecificos,
                  forcaOuFuncaoMuscular:
                    testesEspecificos.forcaOuFuncaoMuscular.filter(
                      (_, itemIndex) => itemIndex !== index
                    ),
                })
              }
            />
            <Spacer size={8} />
          </div>
        ))}
      </div>
      <input
        type="button"
        value={"Adicionar nova"}
        style={{ ...buttonStyle, height: "50px", marginTop: "16px" }}
        onClick={() => setIsInsertingStrenght(true)}
      />
      <Spacer size={16} />
      {isInsertingStrenght && (
        <div>
          <select
            style={{ ...inputStyle, maxWidth: "532px" }}
            value={newStrenght.item}
            onChange={(k) =>
              setNewStrenght({
                ...newStrenght,
                item: k.target.value,
              })
            }
          >
            {STRENGHT_ITEMS.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
          <Spacer size={16} />
          <div style={inputLabelDivStyle}>
            <label>Resultado:</label>
            <textarea
              style={{ ...inputStyle, maxWidth: "512px" }}
              value={newStrenght.resultado}
              rows={1}
              onChange={(k) =>
                setNewStrenght({
                  ...newStrenght,
                  resultado: k.target.value,
                })
              }
            />
          </div>
          <Spacer size={16} />
          <input
            type="button"
            value={"Ok"}
            style={{ ...buttonStyle, height: "50px" }}
            onClick={() => {
              testesEspecificos.forcaOuFuncaoMuscular.push(newStrenght);
              setIsInsertingStrenght(false);
              setNewStrenght(defaultStrenght);
            }}
          />
        </div>
      )}
      <Spacer size={4} />
      <Divider />

      <div style={inputDivStyle}>
        <h3 style={{ color: "white" }}>TESTES COMPLEMENTARES</h3>
        {testesEspecificos.testesComplementares.map((teste, index) => (
          <div key={index}>
            <label>
              {teste.item} - {teste.resultado}
            </label>
            <FontAwesomeIcon
              icon={faXmark}
              color="red"
              style={{ marginLeft: 10 }}
              onClick={() =>
                setTestesEspecificos({
                  ...testesEspecificos,
                  testesComplementares:
                    testesEspecificos.testesComplementares.filter(
                      (_, itemIndex) => itemIndex !== index
                    ),
                })
              }
            />
            <Spacer size={8} />
          </div>
        ))}
      </div>
      <input
        type="button"
        value={"Adicionar nova"}
        style={{ ...buttonStyle, height: "50px", marginTop: "16px" }}
        onClick={() => setIsInsertingComplementaryTest(true)}
      />
      <Spacer size={16} />
      {isInsertingComplementaryTest && (
        <div>
          <div>
            <select
              style={{ ...inputStyle, maxWidth: "532px" }}
              value={newComplementaryTest.item}
              onChange={(k) =>
                setNewComplementaryTest({
                  ...newComplementaryTest,
                  item: k.target.value,
                })
              }
            >
              {COMPLEMENTARY_TESTS_ITEMS.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div>
          <Spacer size={16} />
          <div style={inputLabelDivStyle}>
            <label>Resultado:</label>
            <textarea
              style={inputStyle}
              value={newComplementaryTest.resultado}
              rows={1}
              onChange={(k) =>
                setNewComplementaryTest({
                  ...newComplementaryTest,
                  resultado: k.target.value,
                })
              }
            />
          </div>
          <Spacer size={16} />
          <input
            type="button"
            value={"Ok"}
            style={{ ...buttonStyle, height: "50px" }}
            onClick={() => {
              testesEspecificos.testesComplementares.push(newComplementaryTest);
              setIsInsertingComplementaryTest(false);
              setNewComplementaryTest(defaultComplementaryTest);
            }}
          />
        </div>
      )}
      <Spacer size={4} />
      <Divider />
      <div style={inputLabelDivStyle}>
        <label>Outros:</label>
        <textarea
          style={inputStyle}
          rows={4}
          value={testesEspecificos.outros}
          onChange={(k) =>
            setTestesEspecificos({
              ...testesEspecificos,
              outros: k.target.value,
            })
          }
        />
      </div>
      <Spacer size={4} />
    </TabBar>
  );
};
