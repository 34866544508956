export interface ReportInfo {
  [key: string]: Report;
}

export interface Report {
  employeeId: string;
  date: string;
  anamnese?: Anamnese;
  analisePostural?: AnalisePostural;
  padraoDeMovimento?: AnaliseDePadraoDeMovimento;
  testesEspecificos?: TestesEspecificos;
  testesFuncionais?: TestesFuncionaisPilates;
  diagnostico: Diagnostico;
}

export enum CaracteristicasDor {
  Continua = "Continua",
  Intermitente = "Intermitente",
  PeriodicaOuOcasional = "PeriodicaOuOcasional",
  Episodica = "Episodica",
}

export enum Duracao {
  Aguda = "Aguda",
  SubAguda = "SubAguda",
  Cronica = "Cronica",
}

export enum Ritmo {
  DegenerativaOuMecanica = "DegenerativaOuMecanica",
  Inflamatoria = "Inflamatoria",
  InflamatoriaCronica = "InflamatoriaCronica",
  InflamatoriaAguda = "InflamatoriaAguda",
  AumentoDeCongestaoArticular = "AumentoDeCongestaoArticular",
  OsseaOuDisturbiosOrganicos = "OsseaOuDisturbiosOrganicos",
}

export interface FuncionalidadeEIncapacidade {
  localizacaoDaDor: string;
  inicioDaDor: "súbito" | "gradual" | "lento";
  caracteristicaDaDor: CaracteristicasDor;
  duracaoDaDor: Duracao;
  ritmoDaDor: Ritmo;
  irradiacaoDaDor: {
    presente: boolean;
    regiao?: string;
  };
  intensidade: number;
  fatoresQuePioramADor: { presente: boolean; descricao: string };
  fatoresQueMelhoramADor: { presente: boolean; descricao: string };
  informacoesComplementares: string;
  limitacoesEmAtividade: string;
  restricoesDeParticipacao: string;
}

export enum Nivel {
  Sedentário = "Sedentário",
  Ativo = "Ativo",
  AtletaAmador = "AtletaAmador",
  AtletaProfissional = "AtletaProfissional",
}

export interface FatoresContextuais {
  observacoes: string;
  historicoDeSaudeDoPaciente: string;
  historicoDeAtividadesFisicas: AtividadeFisica[];
  medicamentosEmUso: string;
  examesComplementares: string;
  objetivosDoCliente: string;
  aplicacaoDeQuestionariosEspecificos: string;
  dadosVitais: {
    pressaoArterial: string;
    peso: number;
    altura: number;
    outros: string;
  };
}

export interface AtividadeFisica {
  atividadePraticada: string;
  nivel: Nivel;
  frequencia: number;
}

export interface Anamnese {
  posturaDuranteODia: string;
  objetivosNaClinica: ObjetivosNaClinica;
  diagnosticoMedico: string;
  queixaPrincipal: string;
  funcionalidadeEIncapacidade: FuncionalidadeEIncapacidade;
  fatoresContextuais: FatoresContextuais;
}

export enum ObjetivosNaClinica {
  Reabilitacao = "Reabilitação",
  Prevenção = "Prevenção",
  PromocaoDeSaude = "Promoção de Saúde",
}

export interface Teste {
  item: string;
  resultado: string;
}

export interface AnalisePostural {
  MMII: string;
  tronco: string;
  MMSS: string;
}

export interface AnaliseDePadraoDeMovimento {
  ritmoEscapuloumeral: string;
  ritmoLombopelvico: string;
  inclinacaoLateralDeTronco: string;
  rotacaoDeTronco: string;
  extensaoDeTronco: string;
  quadrupede: string;
  pranchaLateral: string;
  ponteComExtensaoUnilateralDeJoelho: string;
  agachamentoBipodal: string;
  agachamentoUnipodal: string;
  caminhadaOuCorrida: string;
  saltoBipodalOuUnipodal: string;
  outros: string;
}

export interface TestesEspecificos {
  mobilidade: Teste[];
  flexibilidade: Teste[];
  forcaOuFuncaoMuscular: Teste[];
  testesComplementares: Teste[];
  outros: string;
}

export interface TesteUnilateral {
  isometria?: number;
  score: number;
  observacoes: string;
}

export interface TesteDireitoEEsquerdo {
  direito: number;
  esquerdo: number;
  observacoes: string;
  isometria?: number;
}

export interface TestesFuncionaisPilates {
  agachamentoBipodal: TesteUnilateral;
  flexaoQuadrilEmPe: TesteDireitoEEsquerdo;
  ritmoLombopelvico: TesteUnilateral;
  rotacaoMedialOmbros: TesteDireitoEEsquerdo;
  rotacaoLateralOmbros: TesteDireitoEEsquerdo;
  rotacaoDeTronco: TesteDireitoEEsquerdo;
  sereia: TesteDireitoEEsquerdo;
  ponteUnipodal: TesteDireitoEEsquerdo;
  straightLegRaise: TesteDireitoEEsquerdo;
  flexaoDeOmbrosEmDD: TesteDireitoEEsquerdo;
  rollUp: TesteUnilateral;
  sideLift: TesteDireitoEEsquerdo;
  quadrupede: TesteDireitoEEsquerdo;
  legPull: TesteDireitoEEsquerdo;
  extensaoDeQuadrisEmDV: TesteDireitoEEsquerdo;
  swan: TesteUnilateral;
}

export interface Diagnostico {
  diagnosticoFisioterapeutico: string;
  objetivosDoTratamentoFisioterapeutico: string;
}
