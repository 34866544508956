import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loading from "../components/Loading";
import TabBar from "../components/TabBar";
import { db } from "../firebase/firebase-config";
import {
  buttonStyle,
  headerStyle,
  inputDivStyle,
  inputLabelDivStyle,
  inputStyle,
} from "../styles/components";
import { AnalisePostural } from "./ReportsCRUD";
import Spacer from "../components/Spacer";

export default () => {
  const { costumerId, date } = useParams();
  const [postural, setPostural] = useState<AnalisePostural>();

  useEffect(() => {
    const getAnamnese = async () => {
      if (!costumerId) return;
      const reportRef = doc(db, "report", costumerId);
      const docSnap = await getDoc(reportRef);
      const result = docSnap.data()?.[date!].analisePostural as AnalisePostural;
      const posturalResult: AnalisePostural = {
        MMII: result?.MMII || "",
        tronco: result?.tronco || "",
        MMSS: result?.MMSS || "",
      };
      setPostural(posturalResult);
    };
    getAnamnese();
  }, []);

  const navigate = useNavigate();

  const updateReport = async () => {
    const reportDoc = doc(db, "report", costumerId!);
    try {
      await setDoc(
        reportDoc,
        {
          [date!]: {
            analisePostural: postural,
          },
        },
        { merge: true }
      );
      alert("Análise Postural atualizada com sucesso!");
      navigate(`/editar-ficha/${costumerId}/${date}`);
    } catch (e) {
      alert("Erro ao atualizar os dados de análise postural!");
      console.error(e);
    }
  };

  if (!postural)
    return (
      <TabBar selected="Ficha de pacientes">
        <Loading />
      </TabBar>
    );

  return (
    <TabBar selected="Ficha de pacientes">
      <div style={headerStyle}>
        <button type="button" style={buttonStyle} onClick={() => navigate(-1)}>
          Voltar
        </button>
        <h1>Análise Postural</h1>
        <button
          style={buttonStyle}
          type="button"
          onClick={() => updateReport()}
        >
          Salvar
        </button>
      </div>
      <Spacer size={32} />
      <div style={inputLabelDivStyle}>
        <label>MMII</label>
        <textarea
          style={inputStyle}
          rows={3}
          value={postural.MMII}
          onChange={(k) => setPostural({ ...postural, MMII: k.target.value })}
        />
      </div>
      <div style={inputLabelDivStyle}>
        <label>Tronco:</label>
        <textarea
          style={inputStyle}
          id="tronco"
          rows={3}
          value={postural.tronco}
          onChange={(k) => setPostural({ ...postural, tronco: k.target.value })}
        />
      </div>
      <div style={inputLabelDivStyle}>
        <label>MMSS:</label>
        <textarea
          style={inputStyle}
          id="diagnosticoMedico"
          rows={3}
          value={postural.MMSS}
          onChange={(k) => setPostural({ ...postural, MMSS: k.target.value })}
        />
      </div>
    </TabBar>
  );
};
