import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { color } from "../styles/components";
import Spacer from "./Spacer";

export interface TabBarItemAttribute {
  name: string;
  icon: IconDefinition;
  onClick?: () => void;
}

interface Props {
  item: TabBarItemAttribute;
  selected?: boolean;
}

export default (props: Props) => {
  const [mouseOver, setMouseOver] = useState(false);

  return (
    <div
      style={combineContainerStyles()}
      onClick={() => props.item.onClick!()}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <div style={styles.iconContainer}>
        <FontAwesomeIcon
          icon={props.item.icon}
          size={"2x"}
          color={props.selected || mouseOver ? "black" : "white"}
        />
      </div>
      <Spacer size={4} row />
      <div style={styles.labelContainer}>
        <label style={combineItemNameStyles()}>{props.item.name}</label>
      </div>
    </div>
  );

  function combineItemNameStyles() {
    return props.selected || mouseOver
      ? { ...styles.textItem, ...styles.selectedText }
      : { ...styles.textItem };
  }

  function combineContainerStyles() {
    if (props.selected)
      return { ...styles.container, backgroundColor: "white" };

    return mouseOver
      ? { ...styles.container, backgroundColor: color.softBlue }
      : { ...styles.container };
  }
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row" as "row",
    padding: 16,
    flexWrap: "wrap" as "wrap",
    borderStyle: "solid none none",
    borderColor: color.softBlue,
    borderWidth: 1,
    cursor: "pointer",
  },
  iconContainer: {
    width: "48px",
    display: "flex",
    justifyContent: "center",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as "column",
  },
  textItem: {
    fontSize: 18,
    color: "white",
    cursor: "pointer",
  },
  selectedText: {
    color: "black",
  },
};
