import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useContext, useState } from "react";
import { UserContext } from "../App";
import LogoSpace from "../images/logo-space_branco.png";
import { buttonStyle, inputStyle } from "../styles/components";

export default () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const setUser = useContext(UserContext)[1];

  const auth = getAuth();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <img src={LogoSpace} />
      <div>
        <label>E-mail:</label>
        <br />
        <input
          style={{ ...inputStyle, borderRadius: 10 }}
          size={25}
          required
          type="email"
          id="email"
          value={email}
          onChange={(k) => setEmail(k.target.value)}
        />
      </div>
      <div>
        <label>Senha:</label>
        <br />
        <input
          style={{ ...inputStyle, borderRadius: 10 }}
          size={25}
          required
          type="password"
          id="password"
          value={password}
          onChange={(k) => setPassword(k.target.value)}
        />
      </div>
      <button
        style={{
          ...buttonStyle,
          height: 50,
          marginTop: 20,
          fontWeight: "bold",
          color: "white",
        }}
        onClick={() =>
          signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              const userInfo = userCredential.user;
              setUser(userInfo.uid);
            })
            .catch((error) => {
              alert(`Erro ao fazer login!`);
            })
        }
      >
        Entrar
      </button>
    </div>
  );
};
